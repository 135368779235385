import React, { useState } from 'react'
import Box from '@mui/system/Box'
import DashboardLayoutRoute from '../../layout/dashboard-layout'
import RoundedContainer from '../../components/roundedcontainer'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Buttons from '../../atoms/buttons/Button'
import { UserEmailLogin } from '../../controllers/AuthController'
import { toast } from "react-toastify";
import { socialLogin } from '../../controllers/AuthController'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import GoogleIcon from '@mui/icons-material/Google';
import { useNavigate,Navigate } from 'react-router-dom'
import  CircularProgress  from '@mui/material/CircularProgress'
import { useRecoilState } from 'recoil'
import { userState } from '../../recoil/atom/userState'
// import { ForkLeft } from '@mui/icons-material'


const Login = () => {
    const height = window.innerHeight;
    const navigate = useNavigate()
    const [loginEmail, setLoginEmail] = useState("")
    const [loginPassword, setLoginPassword] = useState("")
    const [loading, setLoading] = useState(false);
    const [loggedin, setLoggedInState] = useRecoilState(userState)


    const googleClick = async () => {
        const res = await socialLogin('google');
        if (res.success) {
            localStorage.setItem('user',JSON.stringify(res.data));
            <Navigate to="/sign-in" />
            toast("Logged in successfully", { type: 'success' });
        }
    }

    const facebookClick = async () => {
        setLoading(true)
        const res = await socialLogin('facebook');
        if (res.success) {
            const u = JSON.stringify(res.data)
            setLoggedInState(localStorage.setItem('user',u));
            window.location.href = '/';
            setLoading(false)
            toast("Logged in successfully", { type: 'success' });
        } else {
            setLoading(false)
            toast("Something went wrong", { type: 'error' });
        }
    }

    const LoginWithEmail = async () => {
        if (loginEmail && loginPassword) {
            if (loading === false) {
                setLoading(true)
                const result = await UserEmailLogin(loginEmail, loginPassword)
                console.log(result,result.success,'ress')
                if (result.success) {
                    localStorage.setItem('user',JSON.stringify(result.data))
                    window.location.href = '/';
                    toast("Logged in successfully", { type: 'success' });
                    setLoading(false)
                } else {
                    toast("Something went wrong", { type: 'error' });
                    setLoading(false)
                }
            }
        }
        else {
            toast("Email and password is required.", { type: 'error' });

        }
    }

    return (
        <>
            <RoundedContainer>
                <Box sx={{ height: `calc(${height}px - 100px)`, width: "100%", position: "relative", boxSizing: "border-box" }}>
                    <Box sx={{
                        width: { md: "25%", xs: "100%" },
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                    }}>
                        <Typography sx={{ textAlign: "center", mb: "20px", fontSize: "19px", fontWeight: 600 }}>Acceda a su cuenta Neurosuggest</Typography>
                        <Box>
                            <Typography variant='subtitle2' mb={1}>Usuario</Typography>
                            <TextField
                                fullWidth
                                sx={{ mb: "20px" }}
                                required
                                size="small"
                                type='email'
                                onChange={(e) => setLoginEmail(e.target.value)}
                            />
                            <Typography variant='subtitle2' mb={1}>Contraseña</Typography>
                            <TextField
                                fullWidth
                                required
                                size="small"
                                type='password'
                                onChange={(e) => setLoginPassword(e.target.value)}
                            />
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "end", mb: "20px" }}>
                            <Typography variant='caption' onClick={() => navigate("/email")} sx={{ textAlign: "right", cursor: "pointer" }}>¿No puedes iniciar sesión?</Typography>
                        </Box>
                        <Box sx={{ mb: "20px", display: "flex", justifyContent: "end" }}>
                            <Buttons.MaroonButton sx={{ opacity: loading ? 0.6 : 1 }} type='button' onClick={LoginWithEmail} variant="contained">
                                {loading ?
                                    <>Acceder
                                    </>
                                    :
                                    <>Acceder</>
                                }
                            </Buttons.MaroonButton>
                        </Box>
                        <Typography sx={{ textAlign: "center", mb: 1 }}>¿No está registrado?
                            <Button type='text' onClick={() => navigate("/sign-up")}>
                                Cree una cuenta.
                            </Button>
                        </Typography>
                        <Box display="flex flex-col">
                            <Box mb={1}>
                                <Button sx={{ backgroundColor: "white", borderRadius: "12px", color: "black" }} variant="outlined" fullWidth onClick={() => googleClick()}>
                                    <GoogleIcon sx={{ mr: 1, fontSize: "18px", fontWeight: 600 }} />
                                    Sign up with Google
                                </Button>
                            </Box>
                            <Box mb={1}>
                                <Button sx={{ backgroundColor: "#4267B2", borderRadius: "12px" }} variant="contained" fullWidth onClick={() => facebookClick()}>
                                    <FacebookOutlinedIcon sx={{ mr: 1, fontSize: "18px" }} />
                                    Continuar con Facebook
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </RoundedContainer>
            {loading && (
                <>
                    <Box
                        sx={{
                            position: "fixed",
                            top: "0",
                            right: "0",
                            left: "0",
                            bottom: "0",
                            zIndex: "99",
                            textAlign: "center",
                        }}
                    >
                        <CircularProgress sx={{ position: "absolute", top: "50%" }} />
                    </Box>
                </>
            )
            }
        </>
    )
}
Login.getLayout = (page) => (
    <DashboardLayoutRoute>
        {page}
    </DashboardLayoutRoute>
);

export default Login;
