export const urlToBase64 = (setImage,url) => {
  let img  = new Image();
  let baseUrl = '';
  
  img.onload = function(){
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
     baseUrl = canvas.toDataURL();
    setImage(baseUrl);
  }
  img.setAttribute('crossorigin', 'anonymous');
  img.src = url;
  return img.onload();
  }

