import { Box, Button, IconButton, Switch, Typography } from "@mui/material"
import { TextField } from "@mui/material"
import Buttons from "../../../atoms/buttons/Button"
import RoundedContainer from "../../../components/roundedcontainer"
import { useEffect, useState } from "react"
import InputAdornment from '@mui/material/InputAdornment';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { getUserById } from "../../../controllers/UserController"
import { getFbUserPemissions } from "../../../controllers/AuthController"
import { deleteFbPermissions } from "../../../controllers/AuthController"
import { useNavigate } from "react-router-dom"
import { revokeFbPermissions } from "../../../controllers/AuthController"
import { toast } from "react-toastify"
import CircularProgress from "@mui/material/CircularProgress"

const Profile = () => {
    const height = window.innerHeight;
    const [name, setName] = useState('')
    const [userObject, setUserObject] = useState({})
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(true)
    const user = localStorage.getItem('user')
    const [socailPermissions, setSocailPermissions] = useState([])
    const [somePermissionDisabled, setSomePermissionDisabled] = useState(false)
    const navigate = useNavigate()
    const [read, setRead] = useState(false)

    const handleSwitchChange = async (value, index) => {
        if (value === false) {
            console.log(userObject, 'userObject')
            const perm = socailPermissions[index]
            const resp = await revokeFbPermissions(userObject.fbId, perm.permission, userObject.token,)
            if (resp.success) {
                const updatedPermissions = [...socailPermissions];
                updatedPermissions[index].status = 'declined'
                setSocailPermissions(updatedPermissions)
                setSomePermissionDisabled(true)
            }
            else {
                toast('Error in removing permssion')

            }

            // console.log(resp,'resp')

        }

    }

    const handleLogout = () => {
        localStorage.clear()
        navigate('/sign-in')

    }

    const deleteAllPermissions = async () => {
        const result = await deleteFbPermissions(userObject.fbId, userObject.token)
        if (result.success) {
            // await getSocialPermissions(userObject)
            handleLogout()
        }
    }

    useEffect(() => {
        if (!user) return;
        console.log('usss===>')
        const userData = JSON.parse(user)
        getUserData(userData.userId)
        setName(userData.name)
        setEmail(userData.email)
    }, [user])

    const getUserData = async (uId) => {
        setLoading(true)
        const d = await getUserById(uId)
        if (d.data.providerId === 'facebook.com') {
            getSocialPermissions(d.data)
        }
        setUserObject(d.data)
        setLoading(false)
    }

    const getSocialPermissions = async (userData) => {
        setLoading(true)
        const socialPermissions = await getFbUserPemissions(userData.fbId, userData.token)
        console.log(socialPermissions, 'socialPermissions');
        setSocailPermissions(socialPermissions)
        ifPermissionIsDeclined(socialPermissions)
        setLoading(false)

    }

    const ifPermissionIsDeclined = (permArray) => {
        permArray.forEach(element => {
            if (element.status !== "granted") {
                setSomePermissionDisabled(true)
                return;
            }

        });


    }

    const Read = () => {
        setRead(true)
    }

    if (loading) {
        return (
            <>
                <Box
                    sx={{
                        position: "fixed",
                        top: "0",
                        right: "0",
                        left: "0",
                        bottom: "0",
                        zIndex: "99",
                        textAlign: "center",
                    }}
                >
                    <CircularProgress sx={{ position: "absolute", top: "50%" }} />
                </Box>
            </>
        )
    }
    else {

        return (
            <>
                <RoundedContainer>
                    <Box sx={{ height: `calc(${height}px - 100px)`, overflowY: "auto", position: "relative", boxSizing: "border-box", py: { md: 7, xs: 3 } }}>
                        <Box sx={{
                            width: { md: "25%", xs: "100%" },
                            marginInline: "auto",
                        }}>
                            <Typography sx={{ textAlign: "center", mb: "40px", fontSize: "19px", fontWeight: 600 }}>Perfil de usuario</Typography>
                            <Box>
                                <Typography variant='subtitle2' mb={1}>Nombre</Typography>
                                <TextField
                                    fullWidth
                                    sx={{ mb: "10px" }}
                                    required
                                    type="text"
                                    id="outlined-required"
                                    size="small"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton onClick={Read}>
                                                    <EditIcon />
                                                </IconButton>
                                            </InputAdornment>,
                                        readOnly: read ? false : true,
                                    }}
                                />
                                <Typography variant='subtitle2' mb={1}>E-mail</Typography>
                                <TextField
                                    fullWidth
                                    sx={{ mb: "10px" }}
                                    type="text"
                                    disabled
                                    id="outlined-required"
                                    size="small"
                                    value={email}
                                />
                                {
                                    (socailPermissions?.length > 0) && (
                                        <>
                                            <Typography variant='subtitle2' mb={1}>Contraseña</Typography>
                                            <RoundedContainer>
                                                {socailPermissions.map((value, index) => (
                                                    <Box key={index} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                        <Typography sx={{ fontSize: "15px" }}>{value.permission}</Typography>
                                                        <Switch onChange={(e, checked) => handleSwitchChange(checked, index)} checked={value.status === 'granted' ? true : false} />
                                                    </Box>
                                                ))}
                                            </RoundedContainer>

                                        </>
                                    )
                                }
                                {
                                    somePermissionDisabled && (
                                        <Box pt={1} pb={2}>
                                            <Typography sx={{ fontSize: "12px" }}>Para el correcto funcionamiento de la aplicación, todos los permisos anteriores deben estar concedidos, para aceptarlos debe cerrar sesión y volver a iniciar sesión, con Facebook.</Typography>
                                        </Box>
                                    )
                                }

                                <Box mb={1} mt={1}>
                                    <Button onClick={() => deleteAllPermissions()} variant="contained" fullWidth>Quitar todos los permisos</Button>
                                </Box>
                                <Box mb={1}>
                                    <Button onClick={() => handleLogout()} variant="contained" fullWidth>Cerrar sesión</Button>
                                </Box>
                                <Box my={2}>
                                    <Buttons.WhiteButton>
                                        <KeyboardBackspaceIcon />
                                        Atrás
                                    </Buttons.WhiteButton>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </RoundedContainer>
            </>
        )
    }

}

export default Profile;