import { Box } from '@mui/material';
import React from 'react';
import Header from '../components/header';
import { windowWidth } from '../utils';


const DashboardLayout = ({ children }) => {
  const height = window.innerHeight;
  console.log(windowWidth, 'windowWidth')


  return (
    <React.Fragment>
      <Box
        sx={{
          backgroundImage: "url(/assets/images/unsplash-bg.svg)",
          p: 1,
          boxSizing: "border-box",
          width: "100%", height: height, backgroundSize: "cover", backgroundRepeat: "no-repeat"
        }}
      >
        <Box sx={{ mb: "8px" }}>
          <Header />
        </Box>
        {children}
      </Box>
    </React.Fragment>

  );
};
export default DashboardLayout;
