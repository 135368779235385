import React from 'react';
import { RecoilRoot } from 'recoil';
import AppRoutes from './routes';
import ThemeComponent from './theme/ThemeComponent';


function App() {
  return (
    <React.StrictMode>
      <RecoilRoot>
        <ThemeComponent>
          <AppRoutes />
        </ThemeComponent>
      </RecoilRoot>
    </React.StrictMode>
  );
}

export default App;
