import React, { useEffect } from 'react'
import { useState } from 'react'
import Box from '@mui/system/Box'
// import { Navigate } from "react-router-dom";
import RoundedContainer from '../../../../components/roundedcontainer'
import CustomFooter from '../../../../components/main/customfooter'
import { AddDocuments, CreateDocument, GetDocument, UpdateDocument } from '../../../../controllers/AuthController'
import Step1 from '../../../../components/main/step/step1'
import Step2 from '../../../../components/main/step/step2'
import Step3 from '../../../../components/main/step/step3'
import Step4 from '../../../../components/main/step/step4'
import Step5 from '../../../../components/main/step/step5'
import { toast } from "react-toastify";
import { getGeneratedImage } from '../../../../controllers/ProjectController'
import PageLoader from '../../../../components/pageLoader'
import { useSearchParams,useLocation } from "react-router-dom";
import { getProjectById } from '../../../../controllers/ProjectController'




const ProjectEdit = () => {
    const height = window.innerHeight;
    const [active, setActive] = useState(1);
    const [errorStep, setErrorStep] = useState(-1);
    // const [user, setUser] = useState({})

    const [docId,setDocId] = useState('');
    const [mainImage, setMainImage] = useState(null)

    const [loading,setLoading] = useState(false)
    const { search,state } = useLocation();
    console.log(state)
    const [searchParams] = useSearchParams(); 
    const querParamSearchId = Object.fromEntries([...searchParams]).projectId

    const projectData = {
        projectName:'',
        description:'',
        projectIndustry:[],
        logo:'',
        logoColor:'',
        socailMediaNetwork:[],
        targetAudiance:'',
        selectedMobileImage:'',
        selectedDesktopImage:'',
        mobileDimensions:{},
        desktopDimensions:{},
        imgTitle:'',
        imgSubtitle:'',
        imgCtaText:'',
        imgSrc:'',
        langugage:'',
        bgColor1:'#ffffff',
        bgColor2:'#ffffff',
        bgColor3:'#333333',
        hasUrl:false,
        image:null,
        textTonality:null,
    }
    const [data,setProjectData] = useState(projectData)

    useEffect(()=>{     
        if(querParamSearchId !== ''){
            setDocId(querParamSearchId)
        }
    },[])


    useEffect(()=>{
        setActive(state?.activatedStep)
    },[])




    useEffect(()=>{
        if (!docId) return;

        (async()=>{
            const respone  = await getProjectById(docId)
            if(respone.success){
                console.log(respone.data,'Project data')
                setProjectData({...data,...respone.data})
            }
        })()
    },[docId])







    // const checkStep = async (step) => {
    //     if (step === 1) {
    //         if (data.projectName && data.description && data.audienceValues && data.LogoImage && data.brand
    //         ) {
    //             return true
    //         } else {
    //             return false
    //         }
    //     }
    //     else if (step === 2) {
    //         if (data?.imageLayout?.width) {
    //             return true
    //         } else {
    //             return false
    //         }
    //     }
    //     else if (step === 3) {
            
    //         if (data.TextTonality && data.ctaTitle && data.punchline && data.cta && data.Language && mainImage) {
    //             return true
    //         } else {
    //             return false
    //         }
    //     }
    //     else if (step === 4) {
    //         return true
    //     }
    //     else if (step === 5) {
    //         return true
    //     }
    //     else{
    //         return true;
    //     }
    // }


    const updateStepCall = (val) => {
        if(val === 3){
            if(data.desktopDimensions.width){
                setActive(val)
            }
            else{
                return false;
            }
        }
        else if(val === 4){
            
            if(data.image){
                setActive(val)
            }
            else{
                return false;
            }
        }
        else if(val === 5){
            if(data.adsImage){
                setActive(val)
            }
            else{
                return false;
            }
        }
        else{
            setActive(val)
        }
        
    }



    return (
        <>
            {loading === true && (
                <PageLoader />
            )}
            <RoundedContainer>
                <Box sx={{ height: `calc(${height}px - 100px)`, position: "relative", boxSizing: "border-box" }}>
                    <Box sx={{ height: "100%", display: "flex", position: "relative", flexDirection: "column", justifyContent: "space-between" }}>
                        {/* <Box sx={{
                            mt: { md: `calc(${height}px - ${height - 60}px)`, xs: 2 },
                            mb: `calc(${height}px - ${height - 55}px)`,
                            overflowY: { md: "hidden", xs: "auto" },
                            height: "100%",
                        }}> */}
                            {active === 1 &&
                                <Step1 
                                edit={true}
                                data={data} 
                                setData={setProjectData}
                                activeStep = {active}
                                updateStep = {updateStepCall}
                                projectId = {docId}
                                />
                            }
                            {active === 2 &&
                                <Step2 
                                data={data} 
                                setData={setProjectData}
                                activeStep = {active}
                                updateStep = {updateStepCall}
                                projectId = {docId}
                                 />
                            }
                            {active === 3 &&
                                <Step3 
                                data={data} 
                                setData={setProjectData}
                                activeStep = {active}
                                updateStep = {updateStepCall}
                                projectId = {docId}
                                 />
                            }
                            {active === 4 &&
                                <Step4 
                                data={data} 
                                setData={setProjectData}
                                errorStep={errorStep}
                                activeStep = {active}
                                updateStep = {updateStepCall}
                                projectId = {docId}
                                 />
                            }
                            {active === 5 &&
                                <Step5 
                                data={data} 
                                setData={setProjectData} 
                                errorStep={errorStep}
                                updateStep = {updateStepCall}
                                activeStep = {active}
                                projectId = {docId}
                                 />
                            }
                        {/* </Box> */}
                    </Box>
                </Box>
            </RoundedContainer>
        </>
    )
}

export default ProjectEdit;

