import React from 'react';
import Box from '@mui/system/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CancelIcon from '@mui/icons-material/Cancel';


export default function ModalHeader({ title, description,handleClose }) {
    return (
        <>
            <Box sx={{ width: "100%" }} >
                <Box
                    sx={{ display: "flex",py:1, justifyContent: "space-between", alignItems: "center", flexDirection: { xs: "column", md: "row" }, rowGap: 2 }} >
                    <Box>
                        <Typography sx={{ fontWeight: "600", fontSize: "20px" }} >{title}</Typography>
                        <Typography sx={{ fontSize: "12px", color: "#4D4D4D" }}>{description}</Typography>
                    </Box>
                    <Box>
                        <IconButton onClick={handleClose}>
                            <CancelIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Box sx={{ width: "full", height: "3px", mt: 1, borderRadius: "16px", background: "linear-gradient(45deg, #e7c29f, #e6666c)" }} ></Box>
            </Box>
        </>
    )
}
