import { ThemeProvider, createTheme } from "@mui/material/styles";
import themeOptions from "./ThemeOption";

const ThemeComponent = (props) => {
  const { settings, children } = props;

  const coreThemeConfig = themeOptions(settings);

  let theme = createTheme(coreThemeConfig);
  console.log("theme: ", theme);
  theme = createTheme(theme, {
    // components: {
    //   MuiTextField: {
    //     styleOverrides: {
    //       root: {
    //         fieldset: {
    //           borderColor: "#017BFF",
    //         },
    //       },
    //     },
    //   },
    //   MuiInputBase: {
    //     styleOverrides: {
    //       root: {
    //         fieldset: {
    //           borderColor: "#017BFF",
    //         },
    //       },
    //     },
    //   },
    // },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeComponent;
