import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import React from 'react'
import Typography from '@mui/material/Typography'
import RoundedContainer from '../../roundedcontainer'
import Buttons from '../../../atoms/buttons/Button'
import RoundedNextContainer from '../../roundednextcontainer'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import { useState } from 'react'
import { IndustryList } from '../../../utils/data/IndustryList'



export default function TextsAndCta({ data, setData, handleChange }) {
    const [active, setActive] = useState(0);
    const [selectedBrand, setSelectedBrand] = useState(data.projectIndustry);

    // const [preview, setPreview] = useState(null)
    const IndustryTag = ({ id, label, selected, onClick }) => {
        const handleClick = (e) => {
            e.preventDefault();
            onClick(id);
        };

        return (
            <Box onClick={handleClick}>
                {
                    selected ?
                        (
                            <Buttons.MaroonButton>
                                <Box color={'#fff'}>{label}</Box>
                            </Buttons.MaroonButton>

                        )
                        :
                        (
                            <Buttons.OrangeButton>
                                <Box color={'#fff'}>{label}</Box>
                            </Buttons.OrangeButton>
                        )

                }

            </Box>
        );
    };
    const handleTagClick = (id) => {
        if (selectedBrand.includes(id)) {
            setSelectedBrand(selectedBrand.filter((tagId) => tagId !== id));
            handleChange('projectIndustry', selectedBrand.filter((tagId) => tagId !== id))
        } else {
            handleChange('projectIndustry', [...selectedBrand, id])
            setSelectedBrand([...selectedBrand, id]);
        }

    };

    return (
        <>
            <RoundedContainer style2={{ height: "100%" }}>
                <Box my={0}>
                    <RoundedContainer style={{ display: "flex" }}  >
                        <Grid container >
                            <Grid item xs={12} md={2}>
                                <Typography sx={{ fontSize: "12px", textAlign: { xs: "center", md: "start" } }}>Tonalidad del texto</Typography>
                            </Grid>
                            <Grid item xs={12} md={10}>
                                <Box >
                                    <Box
                                        sx={{
                                            display: "flex", flexWrap: "wrap",
                                            "& .MuiButtonBase-root": { m: 0.2, fontSize: "10px", color: "black" }
                                        }}
                                    >
                                        {IndustryList.map((tag, index) =>
                                            <IndustryTag
                                                key={tag.id}
                                                id={tag.id}
                                                label={tag.label}
                                                onClick={handleTagClick}
                                                selected={data.projectIndustry.includes(tag.id)}
                                            />
                                        )}
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </RoundedContainer>
                    <Grid container sx={{ marginTop: 1, rowGap: 1 }} >
                        <Grid item xs={12} md={8} >
                            <Box sx={{ mr: { xs: 0, md: 1 } }} >
                                <TextField
                                    fullWidth
                                    id="outlined-multiline-flexible"
                                    label="Título"
                                    multiline
                                    size='small'
                                    maxRows={1}
                                    value={data.imgTitle}
                                    onChange={(e) => handleChange('imgTitle', e.target.value)}
                                />
                                <TextField
                                    sx={{ marginTop: 1 }}
                                    fullWidth
                                    id="outlined-multiline-flexible"
                                    label="Subtítulo, opcional"
                                    multiline
                                    size='small'
                                    maxRows={1}
                                    value={data.imgSubtitle}
                                    onChange={(e) => handleChange('imgSubtitle', e.target.value)}
                                />
                                <TextField
                                    sx={{ marginTop: 1 }}
                                    fullWidth
                                    id="outlined-multiline-flexible"
                                    label="Call to Action, opcional"
                                    multiline
                                    size='small'
                                    maxRows={1}
                                    value={data.imgCtaText}
                                    onChange={(e) => handleChange('imgCtaText', e.target.value)}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Box sx={{ ml: { xs: 0, md: 2 } }} >
                                <RoundedNextContainer style={{ padding: "3px" }}>
                                    <Box sx={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }} >
                                        <Typography >Idioma</Typography>
                                        <IconButton size='small' sx={{ background: `${data.langugage === "spanish" ? "lightblue" : ""}` }} onClick={() => setData({ ...data, langugage: "spanish" })}>
                                            <img src='/assets/images/flag-spain.svg' alt='' />
                                        </IconButton>
                                        <IconButton size='small' sx={{ background: `${data.langugage === "english" ? "lightblue" : ""}` }} onClick={() => setData({ ...data, langugage: "english" })}>
                                            <img src='/assets/images/flags_uk.svg' alt='' />
                                        </IconButton>
                                    </Box>
                                </RoundedNextContainer>
                                <Box sx={{ marginTop: 1 }}>
                                    <Buttons.RedButton sx={{ display: "flex", flexDirection: "column" }}>
                                        <Box sx={{ height: "25px" }}>
                                            <img src='/assets/images/Group.svg' height="100%" alt='' />
                                        </Box>
                                        <Box>
                                            GENERAR TEXTOS CON IA
                                        </Box>
                                    </Buttons.RedButton>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </RoundedContainer>
        </>
    )
}
