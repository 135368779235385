import React, { useState, useEffect } from 'react'
import { Box } from '@mui/system'
import { Grid, IconButton, TextField, Typography } from '@mui/material'
import RoundedNextContainer from '../../roundednextcontainer'
import Buttons from '../../../atoms/buttons/Button'
import CustomHeader from '../customheader'
import StepWrapper from '../../stepwrapper'
import SearchIcon from '@mui/icons-material/Search';
import ModalBox from '../../modal'
import { ChromePicker } from 'react-color'
import CircleIcon from '@mui/icons-material/Circle';
import Popover from '@mui/material/Popover'
import ErrorText from '../../ErrorText'
import CustomFooter from '../customfooter'
import { useNavigate } from 'react-router-dom'
import { createProject, updateProjectData, uploadToFirebase } from '../../../controllers/ProjectController'
import { IndustryList } from '../../../utils/data/IndustryList'
import PageLoader from '../../pageLoader'
import { connectFacebook, getUserDetail } from '../../../controllers/AuthController'



const IndustryTag = ({ id, label, selected, onClick }) => {
    const handleClick = (e) => {
        e.preventDefault();
        onClick(id);
    };

    return (
        <Box onClick={handleClick}>
            {
                selected ?
                    (
                        <Buttons.MaroonButton>
                            <Box color={'#fff'}>{label}</Box>
                        </Buttons.MaroonButton>

                    )
                    :
                    (
                        <Buttons.OrangeButton>
                            <Box color={'#fff'}>{label}</Box>
                        </Buttons.OrangeButton>
                    )

            }

        </Box>
    );
};


export default function Step1({ data, setData, updateStep, activeStep, create = false, projectId }) {


    const [open, setOpen] = useState(false);
    const [selectedBrand, setSelectedBrand] = useState(data.projectIndustry);
    const [fileUrl, setFileUrl] = useState(null)
    const [selectedSocial, setSelectedSocial] = useState(data.socailMediaNetwork);
    const height = window.innerHeight;
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [color, setColor] = useState("#F2F2F2");

    const [stateChanged, setStateChanged] = useState(false)

    const [anchorEl, setAnchorEl] = useState(null);

    const [updatedData, setUpdatedData] = useState({})
    const [loading, setLoading] = useState(false);

    const navigation = useNavigate()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePop = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (create) {
            console.log('updating Color')
            handleChange('logoColor', color);
        }

    }, [])

    const openPop = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const ChangeImage = (file) => {
        const { files } = file.target
        if (files && files.length !== 0) {
            setFileUrl(files[0])
        }
    }

    const handleTagClick = (id) => {
        if (selectedBrand.includes(id)) {
            setSelectedBrand(selectedBrand.filter((tagId) => tagId !== id));
            handleChange('projectIndustry', selectedBrand.filter((tagId) => tagId !== id))
        } else {
            handleChange('projectIndustry', [...selectedBrand, id])
            setSelectedBrand([...selectedBrand, id]);
        }

    };

    useEffect(() => {
        let fileReader, isCancel = false;
        if (fileUrl) {
            fileReader = new FileReader();
            fileReader.onload = (e) => {
                const { result } = e.target;
                console.log(result)
                if (result && !isCancel) {
                    handleChange('logo', result)
                }
            }
            fileReader.readAsDataURL(fileUrl);
        }
        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        }
    }, [fileUrl]);


    const handleChange = (name, value) => {
        console.log(name, value)
        setData({
            ...data,
            [name]: value
        })
        setUpdatedData({ ...updatedData, [name]: value })
        setStateChanged(true)
    }

    const handleColor = (color) => {
        setColor(color)
        handleChange('logoColor', color)
    }

    const handleSocial = async (social) => {
        if (data.socailMediaNetwork.includes(social)) {
            // setSelectedSocial(selectedSocial.filter((val) => val !== social));
            handleChange('socailMediaNetwork', data.socailMediaNetwork.filter((val) => val !== social))
        } else {

            if (social === 'Facebook') {
                setLoading(true)
                //console.log('uu')
                //const user = await getUserDetail();
                // if (user.providerId === 'facebook.com' && user.token) {
                //     handleChange('socailMediaNetwork', [...data.socailMediaNetwork, social])
                //     setLoading(false)
                // }
                // else {
                    const connectResult = await connectFacebook('facebook')
                    console.log(connectResult)
                    if (connectResult.data.providerId === 'facebook.com' && connectResult.data.token) {
                        handleChange('socailMediaNetwork', [...data.socailMediaNetwork, social])
                        setLoading(false)
                    }
                    else {
                        alert("connection failed")
                        setLoading(true)
                    }
                //}
            }

            /// handleChange('socailMediaNetwork',[...data.socailMediaNetwork, social])
            // setSelectedSocial([...selectedSocial, social]);
        }
    }

    const nextStepClick = async () => {
        console.log('clicked')
        if (validate() === true) {
            console.log(updatedData, 'updatedData')
            setLoading(true)
            if (create === false) {
                if (stateChanged) {
                    if(updatedData.logo){
                        const logoPath ='project/'+ projectId + '/Logo/'
                        const LogoUrl = await uploadToFirebase(logoPath, updatedData.logo)
                        setData({
                            ...data,
                            logo:LogoUrl
                        })
                        await updateProjectData({...updatedData,logo:LogoUrl}, projectId)
                    }
                    else{
                        await updateProjectData(updatedData, projectId)
                    }
                    //move to next step
                    updateStep(activeStep + 1)
                    setLoading(false);
                }
                else {
                    // move to next step
                    updateStep(activeStep + 1)
                    setLoading(false);
                }
            }
            else {
                const response = await createProject(updatedData);
                if (response.success) {
                    
                    const logoPath = 'project/'+response.docId + '/Logo/'
                    const LogoUrl = await uploadToFirebase(logoPath, updatedData.logo)
                    setData({
                        ...data,
                        logo:LogoUrl
                    })
                    await updateProjectData({...updatedData,logo:LogoUrl}, response.docId)
                    setLoading(false);
                    navigation('/user/project/edit?projectId=' + response.docId, {
                        state: { activatedStep: 2 }
                    })
                }
                else {
                    setLoading(false)
                }
            }
        }

    }

    const validate = () => {
        if (data.projectName && data.description
        ) {
            return true
        } else {
            return false
        }
    }


    return (
        <>
            {loading === true &&
                <PageLoader />
            }
            <Box sx={{
                mt: { md: `calc(${height}px - ${height - 80}px)`, xs: 2 },
                mb: `calc(${height}px - ${height - 60}px)`,
                overflowY: { md: "hidden", xs: "auto" },
                height: "100%",
            }}>
                <CustomHeader
                    title="Crear marca"
                    description="Introduzca los parámetros de su nueva marca."
                />
                <StepWrapper style2={{ overflowY: "auto",}}>
                    <Grid container sx={{ rowGap: 2, height: "100%" }} >
                        <Grid item xs={12} md={6}>
                            <Box sx={{
                                display: "flex",
                                height: "100%",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                mr: { md: 1, xs: 0 }
                            }}>
                                <Box>
                                    <TextField size='small' sx={{ width: "100%" }}
                                        id="outlined-multiline-static"
                                        multiline
                                        rows={2}
                                        placeholder="Introduzca nombre del proyecto"
                                        onChange={(e) => handleChange('projectName', e.target.value)}
                                        value={data.projectName}
                                    />
                                    <ErrorText show={data.projectName?.length <= 0}>Please enter project name.</ErrorText>
                                    <TextField size='small' sx={{ width: "100%", marginTop: 1 }}
                                        id="outlined-multiline-static"
                                        multiline
                                        rows={4}
                                        placeholder='Descripción del proyecto'
                                        onChange={(e) => handleChange('description', e.target.value)}
                                        value={data.description}
                                    />
                                    <ErrorText show={data.description?.length <= 0}>Please enter project description.</ErrorText>
                                </Box>
                                <Box sx={{ mt: 1, height: "100%" }} >
                                    <RoundedNextContainer style={{ height: "100%" }}  >
                                        <Typography>Seleccione los valores de la marca</Typography>
                                        <Box >
                                            <Box item
                                                sx={{
                                                    display: "flex", flexWrap: "wrap",
                                                    "& .MuiButtonBase-root": { m: 1, fontSize: "10px", color: "black" }
                                                }}
                                            >
                                                {IndustryList.map((tag, index) =>
                                                    <IndustryTag
                                                        key={tag.id}
                                                        id={tag.id}
                                                        label={tag.label}
                                                        onClick={handleTagClick}
                                                        selected={(data?.projectIndustry?.length && data.projectIndustry.includes(tag.id))? true : false}
                                                    />
                                                )}
                                            </Box>
                                        </Box>
                                        <ErrorText show={data.projectIndustry.length <= 0}>Please select brands.</ErrorText>
                                    </RoundedNextContainer>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <Box sx={{ ml: { md: 1, xs: 0 }, height: "100%" }}>
                                <Box>
                                    <TextField fullWidth
                                        id="outlined-multiline-static"
                                        multiline
                                        rows={3}
                                        placeholder='Publico objetivo, separar valores por comas'
                                        onChange={(e) => handleChange('targetAudiance', e.target.value)}
                                        value={data.targetAudiance}
                                    />
                                    <ErrorText show={data.targetAudiance?.length <= 0}>Please enter audience value.</ErrorText>
                                </Box>
                                <Grid container fullWidth sx={{ display: "flex", pt: 1, rowGap: 2, height: `calc(100% - 120px)` }}>
                                    <Grid item xs={12} md={6} sx={{ pr: { xs: 0, md: 1 } }} >
                                        <RoundedNextContainer style={{ width: "100%", height: "100%", padding: "16px", display: "flex", flexDirection: "column", justifyContent: "space-evenly" }} >
                                            <Typography sx={{ fontSize: "12px" }}>Introduzca su logotipo</Typography>
                                            <Box
                                                component="form"
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                }}>
                                                <Box component="span"
                                                    sx={{
                                                        position: "relative",
                                                        color: "white",
                                                        padding: "5px 10px",
                                                        borderRadius: "5px"
                                                    }} >
                                                    <img src={data?.logo ? data.logo : "/assets/images/notfound.webp"}
                                                        style={{ borderRadius: "12px", width: "72px", height: "72px", marginBlock: 1 }} alt='Logo' />
                                                </Box>
                                            </Box>

                                            <Typography sx={{ display: "flex", justifyContent: "center", paddingTop: "5px", fontSize: "14px" }} >Arrastre y suelte su logotipo</Typography>

                                            <Box sx={{ display: "flex", justifyContent: "center", paddingTop: 1 }} >
                                                <Buttons.OrangeButton  >
                                                    <Typography>Adjunte un archivo</Typography>
                                                    <input accept="image/*"
                                                        style={{
                                                            position: "absolute",
                                                            top: 0,
                                                            right: 0,
                                                            width: "100%",
                                                            height: "100%",
                                                            margin: 0,
                                                            fontSize: "23px",
                                                            cursor: "pointer",
                                                            opacity: 0,
                                                            direction: "ltr",
                                                        }}
                                                        className="input"
                                                        id="icon-button-file"
                                                        type="file"
                                                        name='...'
                                                        // onChange={(event) => setData({ ...data, LogoImage: event.target.files[0] })}
                                                        onChange={ChangeImage}
                                                    />
                                                </Buttons.OrangeButton>
                                            </Box>
                                            <Grid container sx={{ paddingTop: "10px" }} >
                                                <Grid item xs={12}>
                                                    <Box mr={1}>
                                                        <RoundedNextContainer style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            p: 1,
                                                            alignItems: "center",
                                                        }}>
                                                            <Box>
                                                                <IconButton size='small' onClick={handleClick}>
                                                                    <CircleIcon sx={{ color: { color }, fontSize: "17px" }} />
                                                                </IconButton>
                                                            </Box>

                                                            <Popover
                                                                id={id}
                                                                open={openPop}
                                                                anchorEl={anchorEl}
                                                                onClose={handleClosePop}
                                                                anchorOrigin={{
                                                                    vertical: 'bottom',
                                                                    horizontal: 'left',
                                                                }}
                                                            >
                                                                <ChromePicker
                                                                    color={color}
                                                                    onChange={(updateColor) =>
                                                                        handleColor(updateColor.hex)
                                                                    }
                                                                />
                                                            </Popover>
                                                            <Box>
                                                                <Typography sx={{ fontSize: "14px" }}>{color}</Typography>
                                                            </Box>
                                                        </RoundedNextContainer>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <ErrorText show={!data.logo}>Please select logo.</ErrorText>
                                        </RoundedNextContainer>
                                    </Grid>
                                    <Grid item xs={12} md={6} sx={{ pl: { xs: 0, md: 1 } }}>
                                        <RoundedNextContainer style={{ marginBottom: "10px", width: "100%", height: "100%" }} >
                                            <Typography sx={{ fontSize: "12px" }} >Seleccione las redes sociales que va a usar</Typography>
                                            <Box sx={{ marginTop: 1, display: "flex", flexDirection: "column", justifyContent: "space-evenly" }} >
                                                {/* <Box sx={{ paddingBottom: 2 }}>
                                                    <RoundedNextContainer style={{}}>
                                                        <Box sx={{ display: "flex", justifyContent: "space-between", justifyItems: "center", alignItems: "center" }} >
                                                            <Box sx={{ width: "auto", height: "20px" }} > <img src='/assets/images/google Ads.png' alt='' /></Box>
                                                            <Typography sx={{ color: "#4D4D4D" }} >Google Ads</Typography>
                                                            <Buttons.RedButton onClick={() => handleSocial('Google Ads')} sx={{ display: "flex", justifyContent: "center", justifyItems: "center", gap: "10px" }}>
                                                                <Typography sx={{ fontSize: "12px", fontFamily: "Montserrat" }} >{selectedSocial.includes('Google Ads') ? 'Buscar' : 'Conectar'}</Typography>
                                                                {selectedSocial.includes('Facebook') ?
                                                                    <SearchIcon sx={{ fontSize: "12px" }} />
                                                                    :
                                                                    <img src='/assets/images/tabler_plug-connected.png' alt='' />
                                                                }
                                                            </Buttons.RedButton>
                                                        </Box>
                                                    </RoundedNextContainer>
                                                </Box>
                                                <Box sx={{ paddingBottom: 2 }}>
                                                    <RoundedNextContainer style={{}}>
                                                        <Box sx={{ display: "flex", justifyContent: "space-between", justifyItems: "center", alignItems: "center" }} >
                                                            <Box sx={{ width: "auto", height: "20px" }} > <img src='/assets/images/linkedin.png' alt='' /></Box>
                                                            <Typography sx={{ color: "#4D4D4D" }} >Linkedin</Typography>
                                                            <Buttons.RedButton onClick={() => handleSocial('LinkedIn')} sx={{ display: "flex", justifyContent: "center", justifyItems: "center", gap: "10px" }}>
                                                                <Typography sx={{ fontSize: "12px", fontFamily: "Montserrat" }} >{selectedSocial.includes('LinkedIn') ? 'Buscar' : 'Conectar'}</Typography>
                                                                {selectedSocial.includes('Facebook') ?
                                                                    <SearchIcon sx={{ fontSize: "12px" }} />
                                                                    :
                                                                    <img src='/assets/images/tabler_plug-connected.png' alt='' />
                                                                }
                                                            </Buttons.RedButton>
                                                        </Box>
                                                    </RoundedNextContainer>
                                                </Box> */}
                                                <Box>
                                                    <RoundedNextContainer style={{}}>
                                                        <Box sx={{ display: "flex", justifyContent: "space-between", justifyItems: "center", alignItems: "center" }} >
                                                            <Box sx={{ width: "auto", height: "20px" }} > <img src='/assets/images/facebook.png' alt='' /></Box>
                                                            <Typography sx={{ color: "#4D4D4D" }} >Facebook</Typography>
                                                            <Buttons.RedButton onClick={() => handleSocial('Facebook')} sx={{ display: "flex", justifyContent: "center", justifyItems: "center", gap: "10px" }}>
                                                                <Typography sx={{ fontSize: "12px", fontFamily: "Montserrat" }} >{(selectedSocial?.length && selectedSocial.includes('Facebook')) ? 'Buscar' : 'Conectar'}</Typography>
                                                                {(data?.socailMediaNetwork?.length && data?.socailMediaNetwork?.includes('Facebook')) ?
                                                                    <SearchIcon sx={{ fontSize: "12px" }} />
                                                                    :
                                                                    <img src='/assets/images/tabler_plug-connected.png' alt='' />
                                                                }
                                                            </Buttons.RedButton>
                                                        </Box>
                                                    </RoundedNextContainer>
                                                </Box>
                                            </Box>
                                            <ErrorText show={data?.socailMediaNetwork?.length <= 0}>Please connect social.</ErrorText>
                                        </RoundedNextContainer>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </StepWrapper>
            </Box>
            <CustomFooter
                nextStep={nextStepClick}
                previousDisabled={true}
                activeStep={activeStep}
                updateStep={updateStep}
                create={create}
            />
            <ModalBox open={open} handleClose={handleClose} />
        </>
    )
}
