
export const IndustryList = [
    {
        label:"E-Commerce",
        id:1
    },
    {
        label:"Political",
        id:2
    },
    {
        label:"Consulting",
        id:3
    },
    {
        label:"Education",
        id:4
    },
    {
        label:"Health & Fitness",
        id:5
    },
    {
        label:"Local business",
        id:6
    },
        {
        label:"Tecnología",
        id:7
    },
    {
        label:"Automotive",
        id:8
    },
    {
        label:"Real State",
        id:9
    },
    {
        label:"Travel",
        id:10
    },
    {
        label:"Other",
        id:11
    },

]