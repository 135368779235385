import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { urlToBase64 } from "../../utils/urlToBase64";

export default function Template4(props) {

    const [image,setImage] = useState();
    const [logo,setLogo] = useState();

    useEffect(() => {
     urlToBase64(setImage,props.data.image)
     urlToBase64(setLogo,props.data.logo)
    },[props.data.image])

    const calculateData = {
        height:props.data.desktopDimensions.height/props.division,
        width: props.data.desktopDimensions.width/props.division,
    }

    return (
        <div style={{
            width: calculateData.width,
            height: calculateData.height,
            backgroundColor: '#ddd',
            paddingBlock: '20px',
            borderRadius: '12px',
            border: '1px solid #ddd',
            boxShadow: '1px 2px 5px 1px rgba(0,0,0,0.2)',
            position: 'relative',
            overflow: 'hidden',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '30%',
            backgroundPosition: 'center',
            textAlign: 'center',
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center"
        }}>
            <Box>
                <img
                    src={logo}
                    style={{
                        width: calculateData.width/5,
                        height: calculateData.width/5,
                        borderRadius: '50%',
                        border: '3px solid #ddd',
                        left: 0, right: 0,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginBottom: 2
                    }} alt="" />
            </Box>
            <Box sx={{
                width: "100%",
                height: "100%",
                position: "relative",
                py: "10px",
                my: 2,
            }}>
                <Box sx={{
                    backgroundColor: "#f7836c",
                    width: "90%",
                    height: "100%",
                    zIndex: 1,
                    position: "absolute",
                    right: 0,
                    left: 0,
                    mx: "auto",
                    borderRadius: "20px",
                }}></Box>
                <Box sx={{
                    my: "10px",
                    position: "relative",
                    backgroundImage: 'url(' + image + ')',
                    // backgroundColor: props.data.bgColor3,
                    width: "100%",
                    height: "100%",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    zIndex: 2
                }}>
                </Box>
            </Box>
            <Box>
                <h3 style={{ fontSize: calculateData.width/34, color: props.data.bgColor1, margin: '0px' }}>{props.data.imgSubtitle}</h3>
                <h1 style={{ fontSize: calculateData.width/17, color: props.data.bgColor2, margin: '10px 0px' }}>{props.data.imgTitle}</h1>
                <button style={{
                    padding: calculateData.width/28,
                    fontSize: calculateData.width/38,
                    borderRadius: '12px',
                    border: '0px',
                    background: props.data.bgColor1,
                    minWidth: 100,
                    color: '#fff',
                    fontWeight: '500',
                    outline:'0px'
                }}>{props.data.imgCtaText}</button>
            </Box>
        </div>
    )
}
