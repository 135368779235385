import Box from "@mui/system/Box";

export default function RoundedNextContainer({ children, style }) {
    return (
        <>
            <Box sx={{
                borderRadius: "12px",
                border: "1px solid #FFCD9F",
                overflow: "hidden",
                width: "100%",
                background: " rgba(255, 255, 255, 0.5)",
                p: 1, ...style
            }} >
                {children}
            </Box>

        </>
    )
}