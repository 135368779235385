import React, { useState, useEffect } from 'react'
import Box from '@mui/system/Box'
import RoundedContainer from '../roundedcontainer'
import HomeIcon from '@mui/icons-material/Home';
import Typography from '@mui/material/Typography';
import Buttons from '../../atoms/buttons/Button';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../config/firebaseConfig';
import { useNavigate, useLocation } from 'react-router-dom';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HelpIcon from '@mui/icons-material/Help';

export default function Header() {
    const navigate = useNavigate()
    const location = useLocation();
    const [isLoggedIn, setIsLoggedIn] = useState(JSON.parse(localStorage.getItem('user')))

    // useEffect(() => {
    //     onAuthStateChanged(auth, (user) => {
    //         setIsLoggedIn(!!user);
    //     });
    // }, []);


    return (
        <>
            <RoundedContainer>
                <Box
                    sx={{ display: "flex", justifyContent: "space-between", height: "40px", alignItems: "center" }}
                >
                    <Box>
                        <Button
                            variant='text'
                            onClick={() => navigate("/")}
                            sx={{ ".MuiTypography-root": { fontSize: "25px", fontWeight: 900 } }}
                        >
                            <Typography component="span" sx={{ color: "#FF3E57" }}>N</Typography>
                            <Typography component="span" sx={{ color: "#FE545C" }}>S</Typography>
                        </Button>
                    </Box>
                    {!isLoggedIn &&
                        <Button variant="text">
                            <HomeIcon sx={{ fontSize: "30px" }} />
                        </Button>
                    }
                    {isLoggedIn &&
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                            {location.pathname === "/user/profile" ?
                                <Box sx={{ display: "flex" }}>
                                    <Button variant="text" sx={{ display: "flex", ml: 1 }}>
                                        <HelpIcon sx={{ fontSize: "30px" }} />
                                    </Button>
                                    <Button variant="text" sx={{ display: "flex", ml: 1 }}>
                                        <NotificationsIcon sx={{ fontSize: "30px" }} />
                                    </Button>
                                </Box> :
                                <Buttons.RedButton onClick={() => navigate("/user/project/create")} sx={{ display: { xs: "none", md: "flex" } }}>
                                    <Box component="span" sx={{ display: "flex", mr: 1 }}>
                                        <AddCircleRoundedIcon sx={{ fontSize: "30px" }} />
                                    </Box>
                                    <Box component="span">
                                        Añadir Proyecto
                                    </Box>
                                </Buttons.RedButton>
                            }
                            <Button variant="text" onClick={() => navigate("/user/profile")} sx={{ display: "flex", ml: 1 }}>
                                <PersonIcon sx={{ fontSize: "30px" }} />
                            </Button>
                        </Box>
                    }
                </Box>
            </RoundedContainer>
        </>
    )
}
