import React from 'react'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import Buttons from '../../atoms/buttons/Button'
import { useLocation } from 'react-router-dom';


export default function CustomHeader({ title, description, SaveData }) {
    const location = useLocation();
    return (
        <>
            <Box sx={{ position: { md: "absolute", xs: "relative" }, width: "100%", top: 0 }} >
                <Box
                    sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: { xs: "column", md: "row" }, rowGap: 2 }} >
                    <Box>
                        <Typography sx={{ fontWeight: "600", fontSize: "20px" }} >{title}</Typography>
                        <Typography sx={{ fontSize: "12px", color: "#4D4D4D" }}>{description}</Typography>
                    </Box>
                    {location.pathname === "/" &&
                        <Box sx={{ textAlign: "right" }}>
                            <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>5 / 10</Typography>
                            <Typography sx={{ fontSize: "12px" }}>Proyectos usados / Disponibles</Typography>
                        </Box>}
                    <Box>
                        <Buttons.RedButton onClick={SaveData} sx={{ display: "flex", flexDirection: "column", fontWeight: "700", width: "170px", textAlign: "center", px: "24px" }}>
                            <Typography sx={{ fontSize: "12px", fontWeight: 700 }} >500 / 1.000</Typography>
                            <Typography sx={{ fontSize: "10px", fontWeight: 700 }} >Créditos disponibles</Typography>
                            <Box sx={{ width: "100%", height: "1px", backgroundColor: "#ffffff" }} ></Box>
                            <Typography variant='OVERLINE' sx={{ fontWeight: 300, fontSize: "10px" }}>TRY MODE</Typography>
                        </Buttons.RedButton>
                    </Box>
                </Box>
                <Box sx={{ width: "full", height: "3px", mt: 1, borderRadius: "16px", background: "linear-gradient(45deg, #e7c29f, #e6666c)" }} ></Box>
            </Box>
        </>
    )
}
