import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import { Grid } from '@mui/material';
import RoundedContainer from '../roundedcontainer';
import ModalHeader from '../header/modalHeader';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Button from '@mui/material/Button';
import TextsAndCta from './step/Textsandcta';
import DataImage from './step/DataImage';
import LogoAndColors from './step/logoAndColors';
import { updateProjectData, uploadToFirebase } from '../../controllers/ProjectController';
import Buttons from '../../atoms/buttons/Button';
import PageLoader from '../pageLoader';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    width: { md: "50%", xs: "100%" },
    height: { md: "80%", xs: "100%" },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: "16px",
};

const steps = ['Textos y CTA', 'Logotipo y colores', 'Imagen'];

export default function EditModal({ open, handleClose, data, setData, projectId }) {
    const [activeStep, setActiveStep] = useState(0);
    const [updatedData, setUpdatedData] = useState({});
    const [loading, setLoading] = useState(false);
    // const [completed, setCompleted] = React.useState({});
    const height = window.innerHeight;

    // const totalSteps = () => {
    //     return steps.length;
    // };

    // const completedSteps = () => {
    //     return Object.keys(completed).length;
    // };

    // const isLastStep = () => {
    //     return activeStep === totalSteps() - 1;
    // };

    // const allStepsCompleted = () => {
    //     return completedSteps() === totalSteps();
    // };

    // const handleNext = () => {
    //     const newActiveStep =
    //         isLastStep() && !allStepsCompleted()
    //             ?
    //             steps.findIndex((step, i) => !(i in completed))
    //             : activeStep + 1;
    //     setActiveStep(newActiveStep);
    // };

    // const handleBack = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleChange = (name, value) => {
        setUpdatedData({
            ...updatedData,
            [name]: value
        })
        setData({ ...data, [name]: value })
    }

    const saveDetail = async () => {
        setLoading(true)
        let newData = updatedData;
        if(updatedData.logo){
            const logoPath = 'project/'+projectId + '/Logo/'
            const LogoUrl = await uploadToFirebase(logoPath, updatedData.logo)
            newData = {
                ...newData,
                logo:LogoUrl
            }
        }
        if(updatedData.image){
            const path = 'project/'+projectId + '/Image/'
            const ImageUrl = await uploadToFirebase(path, updatedData.image)
            newData = {
                ...newData,
                image:ImageUrl
            }
        }


        await updateProjectData(newData, projectId)
        handleClose();
        setLoading(false)
    }

    // const handleComplete = () => {
    //     const newCompleted = completed;
    //     newCompleted[activeStep] = true;
    //     setCompleted(newCompleted);
    //     handleNext();
    // };

    function _renderStepContent(step) {
        switch (step) {
            case 0:
                return <TextsAndCta data={data} setData={setData} handleChange={handleChange} />;
            case 1:
                return <LogoAndColors data={data} setData={setData} handleChange={handleChange} />;
            case 2:
                return <DataImage data={data} setData={setData} handleChange={handleChange} />;
            default:
                return <Box>Not Found</Box>;
        }
    }

    // const handleReset = () => {
    //     setActiveStep(0);
    //     setCompleted({});
    // };
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <RoundedContainer style2={{ height: "100%", position: "relative" }}>
                        {loading === true &&
                            <PageLoader />
                        }
                        <ModalHeader
                            title="Edición rápida"
                            description="Cambie los inputs del diseño para hacer iteraciones en tiempo real"
                            handleClose={handleClose}
                        />
                        <Box sx={{ width: '100%', height: `calc(${height}px - 330px)`, pt: 1 }}>
                            <Stepper sx={{ "& .MuiStepConnector-root": { display: "none" } }} nonLinear activeStep={activeStep}>
                                <Grid container sx={{ borderRadius: "8px", overflow: "hidden" }}>
                                    {steps.map((label, index) => (
                                        <Grid key={index} sx={{ display: "flex", justifyContent: "center" }} item xs={12} md={4}>
                                            <Step sx={{ width: "100%", px: 0 }} fullWidth key={label}
                                            // completed={completed[index]}
                                            >
                                                <Button fullWidth color="inherit" sx={{ fontSize: "15px", background: activeStep === index ? "#FE7D68" : "#FFCD9F", borderRadius: 0 }} onClick={handleStep(index)}>
                                                    {label}
                                                </Button>
                                            </Step>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Stepper>
                            <Box>
                                {/* {allStepsCompleted() ? (
                                <React.Fragment>
                                    <Typography sx={{ mt: 2, mb: 1 }}>
                                        All steps completed - you&apos;re finished
                                    </Typography>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <Button onClick={handleReset}>Reset</Button>
                                    </Box>
                                </React.Fragment>
                            ) : ( */}
                                <Box sx={{ position: "relative" }}>
                                    <Box sx={{ mt: 2, mb: 1, py: 1, height: '100%' }}>
                                        {_renderStepContent(activeStep)}
                                    </Box>
                                    {/* <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        <Button
                                            color="inherit"
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            sx={{ mr: 1 }}
                                        >
                                            Back
                                        </Button>
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        <Button onClick={handleNext} sx={{ mr: 1 }}>
                                            Next
                                        </Button>
                                        {activeStep !== steps.length &&
                                            (completed[activeStep] ? (
                                                <Typography variant="caption" sx={{ display: 'inline-block' }}>
                                                    Step {activeStep + 1} already completed
                                                </Typography>
                                            ) : (
                                                <Button onClick={handleComplete}>
                                                    {completedSteps() === totalSteps() - 1
                                                        ? 'Finish'
                                                        : 'Complete Step'}
                                                </Button>
                                            ))}
                                    </Box> */}
                                </Box>
                                {/* )} */}
                            </Box>
                        </Box>
                        {activeStep === steps.length - 1 &&
                            <Buttons.RedButton onClick={saveDetail} sx={{ position: "absolute", bottom: 10, right: 20 }}>Complete</Buttons.RedButton>
                        }
                    </RoundedContainer>
                </Box>
            </Modal>
        </>
    )
}
