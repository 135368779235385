import React from 'react'
import RoundedNextContainer from '../../roundednextcontainer'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CircleIcon from '@mui/icons-material/Circle';
import Grid from '@mui/material/Grid'
import Buttons from '../../../atoms/buttons/Button'
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, Popover } from '@mui/material'
import { useEffect, useState } from 'react'
import { ChromePicker } from 'react-color'
import CircularProgress from '@mui/material/CircularProgress';
import RefreshIcon from '@mui/icons-material/Refresh';


import useImageColor from 'use-image-color'

export default function LogoAndColors({ data, setData,handleChange }) {
  const [fileUrl, setFileUrl] = useState(null)
  const [base64, setBase64] = useState(data.logo ? data.logo : null)
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [refresh, setRefresh] = useState(true);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const [open, setOpen] = useState(false)
  const { colors } = useImageColor(data.logo, { cors: true, colors: 5 })
  // console.log("fileUrl: ", fileUrl);

  const ChangeImage = (file) => {
    const { files } = file.target
    if (files && files.length !== 0) {
      setFileUrl(files[0])
    }
  }

  useEffect(() => {
    let fileReader, isCancel = false;
    if (fileUrl) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        console.log(result)
        if (result && !isCancel) {
          setBase64(result)
          setData({ ...data, logo: result })
          handleChange('logo', result)
        }
      }
      fileReader.readAsDataURL(fileUrl);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    }
  }, [fileUrl]);

  const handleColorClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleColorClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleColorClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };

  const handleClosePop = () => {
    setAnchorEl1(null);
    setAnchorEl2(null);
    setAnchorEl3(null);
  };

  const openPop1 = Boolean(anchorEl1);
  const id1 = open ? 'simple-popover' : undefined;

  const openPop2 = Boolean(anchorEl2);
  const id2 = open ? 'simple-popover' : undefined;

  const openPop3 = Boolean(anchorEl3);
  const id3 = open ? 'simple-popover' : undefined;

  const handleColor = (color, colorFiels) => {
    setData({
      ...data,
      [colorFiels]: color,
    })
  }
  console.log(data, 'image')
  useEffect(() => {
    if (colors !== undefined) {
      getColor();
    }
  }, [colors])

  const getColor = () => {
    setRefresh(true)

    const RandomColor = getMultipleRandom(colors, 3)
    console.log(RandomColor, colors, 'colors')
    setData({
      ...data,
      bgColor1: RandomColor[0],
      bgColor2: RandomColor[1],
      bgColor3: RandomColor[2],
    })
    setRefresh(false)
  }
  function getMultipleRandom(arr, num) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
  }
  return (
    <>
      {/* <Buttons.RedButton sx={{ position: "absolute", bottom: 0, right: 0 }}>Save</Buttons.RedButton> */}
      <Grid container fullWidth sx={{ display: "flex", pt: 1, rowGap: 2, height: "100%" }}>
        <Grid item xs={12} md={6} sx={{ pr: { xs: 0, md: 1 } }} >
          <RoundedNextContainer style={{ width: "100%", hight: "100%px", padding: "16px" }} >
            <Typography sx={{ fontSize: "12px" }}>Introduzca su logotipo</Typography>
            <Box
              component="form"
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
              <Box component="span"
                sx={{
                  position: "relative",
                  color: "white",
                  padding: "5px 10px",
                  borderRadius: "5px"
                }} >
                <img src={base64 ? base64 : "/assets/images/notfound.webp"}
                  style={{ borderRadius: "12px", width: "75px", height: "75px", marginBlock: 1 }} alt='Logo' />
              </Box>
            </Box>

            <Typography sx={{ display: "flex", justifyContent: "center", paddingTop: "5px", fontSize: "14px" }} >Arrastre y suelte su logotipo</Typography>

            <Box sx={{ display: "flex", justifyContent: "center", paddingTop: 1 }} >
              <Buttons.OrangeButton  >
                <input accept="image/*"
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    width: "100%",
                    height: "100%",
                    margin: 0,
                    fontSize: "23px",
                    cursor: "pointer",
                    opacity: 0,
                    direction: "ltr",
                  }}
                  className="input"
                  id="icon-button-file"
                  type="file"
                  name='...'
                  onChange={ChangeImage}
                />
                <Typography>Adjunte un archivo</Typography>
              </Buttons.OrangeButton>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", justifyItems: "center", fontSize: "10px", paddingTop: "10px" }} >
              <RoundedNextContainer style={{
                display: "flex",
                justifyContent: "center",
                p: 2,
                alignItems: "center",
                maxHeight: 2,
                mr: 1,
                width: "100px"
              }}>
                <IconButton size='small' onClick={handleColorClick1}>
                  <CircleIcon sx={{ color: data.bgColor1, fontSize: "17px" }} />
                </IconButton>
                <Popover
                  id={id1}
                  open={openPop1}
                  anchorEl={anchorEl1}
                  onClose={handleClosePop}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <ChromePicker
                    color={data.bgColor1}
                    onChange={(updateColor) =>
                      handleColor(updateColor.hex, 'bgColor1')
                    }
                  />
                </Popover>
                <Box component="span">
                  {data.bgColor1}
                </Box>

              </RoundedNextContainer>
              <RoundedNextContainer style={{
                display: "flex",
                justifyContent: "center",
                p: 2,
                alignItems: "center",
                mr: 1,
                maxHeight: 2,
                width: "100px"
              }}>
                <IconButton size='small' onClick={handleColorClick2}>
                  <CircleIcon sx={{ color: data.bgColor2, fontSize: "17px" }} />
                </IconButton>
                <Popover
                  id={id2}
                  open={openPop2}
                  anchorEl={anchorEl2}
                  onClose={handleClosePop}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <ChromePicker
                    color={data.bgColor2}
                    onChange={(updateColor) =>
                      handleColor(updateColor.hex, 'bgColor2')
                    }
                  />
                </Popover>
                <Box component="span">
                  {data.bgColor2}
                </Box>
              </RoundedNextContainer>
              <RoundedNextContainer style={{
                display: "flex",
                justifyContent: "center",
                p: 2,
                mr: 1,
                alignItems: "center",
                width: "100px",
                maxHeight: 2,
              }}>
                <IconButton size='small' onClick={handleColorClick3}>
                  <CircleIcon sx={{ color: data.bgColor3, fontSize: "17px" }} />
                </IconButton>
                <Popover
                  id={id3}
                  open={openPop3}
                  anchorEl={anchorEl3}
                  onClose={handleClosePop}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <ChromePicker
                    color={data.bgColor3}
                    onChange={(updateColor) =>
                      handleColor(updateColor.hex, 'bgColor3')
                    }
                  />
                </Popover>
                <Box component="span">
                  {data.bgColor3}
                </Box>
              </RoundedNextContainer>
              <RoundedNextContainer style={{
                display: "flex",
                justifyContent: "center",
                p: 2,
                alignItems: "center",
                width: "48px",
                maxHeight: 2,
              }}>
                <Box onClick={() => getColor(colors, 3)}>
                  {refresh === true ?
                    <CircularProgress color='primary' size={18} />
                    :
                    <RefreshIcon color='primary' size={28} />
                  }
                </Box>
              </RoundedNextContainer>
            </Box>
          </RoundedNextContainer>
        </Grid>
        <Grid item xs={12} md={6} sx={{ pl: { xs: 0, md: 1 } }}>
          <RoundedNextContainer style={{ marginBottom: "10px", width: "100%" }} >
            <Typography sx={{ fontSize: "12px" }} >Seleccione las redes sociales que va a usar</Typography>
            <Box sx={{ marginTop: 1, display: "flex", flexDirection: "column", justifyContent: "space-evenly" }} >
              <Box sx={{ paddingBottom: 1 }}>
                <RoundedNextContainer style={{}}>
                  <Box sx={{ display: "flex", justifyContent: "space-between", justifyItems: "center", alignItems: "center" }} >
                    <Box sx={{ width: "auto", height: "20px" }} > <img src='/assets/images/google Ads.png' alt='' /></Box>
                    <Typography sx={{ color: "#4D4D4D" }} >Google Ads</Typography>
                    <Buttons.RedButton
                      // onClick={handleOpen}
                      sx={{ display: "flex", justifyContent: "center", justifyItems: "center", gap: "10px" }}>
                      <Typography sx={{ fontSize: "12px", fontFamily: "Montserrat" }} >Buscar</Typography>
                      <SearchIcon sx={{ fontSize: "12px" }} />
                    </Buttons.RedButton>
                  </Box>
                </RoundedNextContainer>
              </Box>
              <Box sx={{ paddingBottom: 1 }}>
                <RoundedNextContainer style={{}}>
                  <Box sx={{ display: "flex", justifyContent: "space-between", justifyItems: "center", alignItems: "center" }} >
                    <Box sx={{ width: "auto", height: "20px" }} > <img src='/assets/images/google Ads.png' alt='' /></Box>
                    <Typography sx={{ color: "#4D4D4D" }} >Google Ads</Typography>
                    <Buttons.RedButton
                      //  onClick={handleOpen}
                      sx={{ display: "flex", justifyContent: "center", justifyItems: "center", gap: "10px" }}>
                      <Typography sx={{ fontSize: "12px", fontFamily: "Montserrat" }} >Buscar</Typography>
                      <SearchIcon sx={{ fontSize: "12px" }} />
                    </Buttons.RedButton>
                  </Box>
                </RoundedNextContainer>
              </Box>
              <Box>
                <RoundedNextContainer style={{}}>
                  <Box sx={{ display: "flex", justifyContent: "space-between", justifyItems: "center", alignItems: "center" }} >
                    <Box sx={{ width: "auto", height: "20px" }} > <img src='/assets/images/google Ads.png' alt='' /></Box>
                    <Typography sx={{ color: "#4D4D4D" }} >Google Ads</Typography>
                    <Buttons.RedButton
                      //  onClick={handleOpen}
                      sx={{ display: "flex", justifyContent: "center", justifyItems: "center", gap: "10px" }}>
                      <Typography sx={{ fontSize: "12px", fontFamily: "Montserrat" }} >Buscar</Typography>
                      <SearchIcon sx={{ fontSize: "12px" }} />
                    </Buttons.RedButton>
                  </Box>
                </RoundedNextContainer>
              </Box>
            </Box>
          </RoundedNextContainer>
        </Grid>
      </Grid>
    </>
  )
}
