import Step1 from "../../../../components/main/step/step1"
import CustomFooter from "../../../../components/main/customfooter"
import Box from "@mui/material/Box"
import RoundedContainer from "../../../../components/roundedcontainer"
import { createProject } from "../../../../controllers/ProjectController"
import { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom"



function ProjectCreate() {
    const height = window.innerHeight;
    const navigation = useNavigate()
    const [data, setData] = useState({
        projectName: '',
        description: '',
        projectIndustry: [],
        logo: '',
        logoColor: '',
        socailMediaNetwork: [],
        targetAudiance: ''
    })

    return (
        <RoundedContainer>
            <Box sx={{ height: `calc(${height}px - 100px)`, position: "relative", boxSizing: "border-box" }}>
                <Box sx={{ height: "100%", display: "flex", position: "relative", flexDirection: "column", justifyContent: "space-between" }}>
                    <Step1 data={data} setData={setData} create={true} />
                </Box>
            </Box>
        </RoundedContainer>
    )




}
export default ProjectCreate