import React from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { TextField } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: "16px",
};

export default function ModalBox({ open, handleClose }) {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography sx={{ fontSize: "15px", fontWeight: "700", textAlign: "center", pb: 1 }} >Conecte su cuenta de RED SOCIAL</Typography>

                <Box>
                    <Typography sx={{ fontSize: "12px", textAlign: "center", pb: 1 }}>Usuario</Typography>
                    <TextField size='small' sx={{ width: "100%", pb: 1 }}
                        id="outlined-multiline-static"
                    />
                    <Typography sx={{ fontSize: "12px", textAlign: "center", pb: 1 }}>Contraseña</Typography>
                    <TextField size='small' sx={{ width: "100%", pb: 1 }}
                        id="outlined-multiline-static"
                    />
                </Box>
            </Box>
        </Modal>
    )
}
