import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  deleteDoc,
  where,
  updateDoc,
  setDoc,
  getStorage,
} from "firebase/firestore";
import { storage } from "../config/firebaseConfig";
import { ref, uploadBytes, getDownloadURL, uploadString } from "firebase/storage";

import { db } from "../config/firebaseConfig";

export const getGenratedImages = async (requestData) => {
  console.log(requestData, 'requestData')
  const data = JSON.stringify(requestData)
  const apiUrl = "https://europe-west2-neurosuggest.cloudfunctions.net/function-1"
  return fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: data
  })
    .then(res => res.json())
    .then(data => { return { success: true, data: data } })
    .catch(err => { return { success: true, err: err } })
}


export const getGeneratedText = async (requestData) => {
  console.log(requestData, 'requestData')
  const data = JSON.stringify(requestData)
  const apiUrl = "https://europe-west2-neurosuggest.cloudfunctions.net/openai_suggester-api"
  return fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: data
  })
    .then(res => res.json())
    .then(data => { return { success: true, data: data } })
    .catch(err => { return { success: true, err: err } })
     
}


export const createProject = async (data) => {
  console.log(data, 'ddddd')
  try {
    const uid = JSON.parse(localStorage.getItem("user")).userId;

    const projectData = {
      uid: uid,
      projectName: data.projectName,
      description: data.description,
      projectIndustry: data.projectIndustry,
      logoColor: data.logoColor,
      targetAudiance: data.targetAudiance,
      logo: data.logo,
      socailMediaNetwork: data.socailMediaNetwork ? data.socailMediaNetwork : null,
      status: 'draft'
    }
    const result = await addDoc(collection(db, 'projects'), projectData);

    return { docId: result.id, success: true };
  }
  catch (err) {
    console.log("err: ", err);
    return { success: false, error: err }
  }
}

export const updateProjectData = async (data, projectId) => {
  console.log(data, "data")
  console.log(projectId, "projectId")
  const docRef = doc(db, 'projects', projectId)
  try {
    const result = await updateDoc(docRef, data)
    return { success: true, res: result }
  }
  catch (err) {
    console.log('error in update project document', err)
    return { success: false, err: err }

  }

}


export const getProjectById = async (projectId) => {
  const docRef = doc(db, 'projects', projectId)
  try {
    const result = await getDoc(docRef)
    return { success: true, data: result.data() }
  }
  catch (err) {
    console.log('Error in geting doc by id', err)
    return { success: false, err: err }

  }

}

//Path  must be start with project id 
// example: ABCC123/logo/
//exmaple : Abcz/project
//expampl :: ABC/result
export const uploadToFirebase = async (path, imgBase64) => {
  const storageRef = ref(storage, path)
  return await uploadString(storageRef, imgBase64, 'data_url').then(async () => {
    const url = await getDownloadURL(storageRef)
    console.log(url)
    return url;
  })
    .catch(err => {
      console.log(err)
      return { success: false, err: err }
    })
}

export const getProjectByUser = async (userId) => {
  const docRef = collection(db, 'projects')
  const q = query(docRef, where('uid', '==', userId))
  try {
    const result = await getDocs(q)
    const data = [];
    result.forEach(doc => {
      data.push({ ...doc.data(), docId: doc.id })
    });
    return { success: true, data: data }
  }
  catch (err) {
    return { success: true, err: err }

  }

}