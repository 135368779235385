
import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Cropper from 'react-easy-crop'
import { Button } from '@mui/material';
import Buttons from '../../atoms/buttons/Button';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    height: "80%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: "16px",
};
export default function CropModal({ data, open,saveImage, crop,handleClose,zoom,setCrop,onCropComplete,setZoom,image  }) {


    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
            <div className="crop-container">
                    <Cropper
                    image={image}
                    crop={crop}
                    zoom={zoom}
                    aspect={data.desktopDimensions.width / data.desktopDimensions.height }
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    
                    />
                    </div>
                    <Box sx={{   position: 'absolute',
                                bottom: '20px',
                                right: '20px'}}>
                    <Buttons.MaroonButton onClick={saveImage}>Crop Image</Buttons.MaroonButton>
                    
                    </Box>
            </Box>
        </Modal>
    )
}
