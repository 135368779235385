import { Box, Grid, Typography } from "@mui/material"
import Buttons from "../../../atoms/buttons/Button"
import RoundedContainer from "../../../components/roundedcontainer"
import CustomHeader from "../../../components/main/customheader"
import StepWrapper from "../../../components/stepwrapper"
import AddIcon from '@mui/icons-material/Add';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import PageLoader from "../../../components/pageLoader"
import { getProjectByUser } from "../../../controllers/ProjectController"
// import * as htmlToImage from 'html-to-image';
import { useRef } from "react"

const Start = () => {
  const height = window.innerHeight;
  const navigation = useNavigate()
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const domEl = useRef(null);
  const [url,setUrl] = useState('');


  useEffect(() => {
    getAllProjects();
  }, [])

  const getAllProjects = async () => {
    const userid = JSON.parse(localStorage.getItem('user')).userId
    const data = await getProjectByUser(userid);
    console.log(data, 'allproject')
    setLoading(false)
    setData(data.data);
  }

  const createDoc = () => {
    navigation("/user/project/create");
  }
  const updateDoc = (id) => {
    navigation('/user/project/edit?projectId=' + id, {
      state: { activatedStep: 1 }
    })
  }


  // const convertToImage = async() => {
  //   const dataUrl = await htmlToImage.toPng(domEl.current);
  // console.log(dataUrl,'dataurl')
  // setUrl(dataUrl)
  // }

  return (
    <>
      {loading === true && (
        <PageLoader />
      )}
      {/* <div ref={domEl} style={{
              width: '400px',
              height: '500px',
              backgroundImage:'url(./assets/bg.png)',
              padding: '20px',
              borderRadius: '12px',
              border: '1px solid #333',
              boxShadow: '1px 2px 5px 1px rgba(0,0,0,0.2)',
              position: 'relative',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'top right',
              }}>
              <h1 style={{fontSize: '36px',color: '#fff',margin:'0px'}}>Project Title</h1>
              <p style={{fontSize: '16px',color: '#fff'}}>Lorem ipsum doller emit is a dummy content lorem ipsum doller emit is a dummy content</p>
              <img
              src="./assets/flogo.png"
              style={{
                width:100,
                height:100,
                borderRadius:'50%',
                position: 'absolute',
                bottom: '40px',
                border: '3px solid #ddd'}} />
          </div> */}
          {/* <Box onClick={() => convertToImage()}>Convert to image</Box>
     
          <img src={url} /> */}

      <RoundedContainer style2={{overflow:'auto'}}>
        <Box sx={{ height: `calc(${height}px - 100px)`, position: "relative", boxSizing: "border-box" }}>
          <Box sx={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <CustomHeader
              title="Elige marca o proyecto"
              description="Cree y edite nuevos proyectos de marketing."
            />
            <Box pt="75px">
              <StepWrapper>
                <Box sx={{ height: "100%", display: "flex", flexWrap: "wrap" }}>
                  <Box sx={{ my: 1, display: "flex", alignItems: "stretch",  width: { md: "20%", xs: "100%" }, px: 1 }} xs={12} md={2}>
                    <Buttons.RedButton onClick={() => createDoc()} fullWidth height="100%">
                      <Box p={"10px"}>
                        <Box>
                          <AddIcon sx={{ fontSize: "60px", textAlign: "center" }} />
                        </Box>
                        <Box>
                          <Box sx={{ background: "#FE7D68", borderRadius: "12px" }}>
                            <Typography variant="h6" sx={{ fontSize: "24px", textAlign: "center", p: "10px", lineHeight: 1.2 }}>Añadir Proyecto</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Buttons.RedButton>
                  </Box>
                  {data.map((item, index) =>
                    <Box key={index} sx={{ my: 1,width: { md: "20%", xs: "100%" }, px: 1 }} item xs={12} md={2}>
                      <Box sx={{ overflow: "hidden", borderRadius: "12px", backgroundColor: "white", height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <Box sx={{ background: "#EAEAEA", display: "flex", justifyContent: "center", p: "5px" }}>
                          <Typography variant="subtitle1" sx={{ fontSize: "10px" }}>{item.name}</Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                          <img src={item?.adsImage?.image1} width={'100%'} alt="" />
                        </Box>
                        <Box>
                          <Grid container>
                            <Grid item xs={6} sx={{ background: "#EAEAEA", display: "flex", justifyContent: "center", alignItems: "center", p: "5px", columnGap: "10px" }}>
                              <Typography variant="subtitle1" sx={{ fontSize: "10px" }}>Estadísticas</Typography>
                              <EqualizerIcon sx={{ fontSize: "16px" }} />
                            </Grid>
                            <Grid item onClick={() => updateDoc(item.docId)} xs={6} sx={{ background: "rgba(170, 164, 164, 0.5)", display: "flex", justifyContent: "center", alignItems: "center", p: "5px", columnGap: "10px" }}>
                              <Typography variant="subtitle1" sx={{ fontSize: "10px" }}>Ver</Typography>
                              <VisibilityIcon sx={{ fontSize: "16px" }} />
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                  )}

                </Box>
              </StepWrapper>
            </Box>
          </Box>
        </Box>
        
      </RoundedContainer>
    </>
  )
}

export default Start;

