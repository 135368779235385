import { Grid, Typography } from '@mui/material'
import React from 'react'
import Buttons from '../../atoms/buttons/Button'
import RoundedContainer from '../roundedcontainer'
import styled from '@emotion/styled';
// import Box from '@mui/material';

const ButtonCount = styled(Typography)`
    background: white;
    width: 20px;
    height: 20px;
    color: #ffb979;
    border-radius: 50%;
    margin-right: 5px;
    font-size: 14px;
    line-height: 1.5;
`

const ButtonName = ["Mapa de calor", "Orden de visión", "Atención", "Métricas"]

export default function CompareFooter({ active, setActive,btnClick }) {
    return (
        <>
            <RoundedContainer style2={{ border: "none", }} style={{ display: "flex", alignItems: "center", background: "#54acfe20" }}>
                <Grid sx={{ display: "flex", alignItems: "center" }} container fullWidth>
                    <Grid item xs={6} textAlign="center" md={4}>
                        <Typography sx={{ fontSize: "16px", color: "#2E6FAB" }}>Comparar los valores de efectividad</Typography>
                        <Typography sx={{ fontSize: "12px", color: "#2E6FAB" }} >Varios valores seleccionables</Typography>
                    </Grid>
                    <Grid item sx={{ justifyContent: "space-around", display: { xs: "none", md: "flex" } }} xs={8}>
                        {ButtonName.map((buttonName, index) =>
                            <>{active === index ? (
                                <Buttons.DarkBlueButton key={index} sx={{ boxShadow: "0px 0px 2px 5px", borderRadius: "8px!important" }} onClick={() => btnClick(index)}>
                                    {/* <ButtonCount>{index + 1}</ButtonCount> */}
                                    <img src={`/assets/images/icon${index+1}.png`} style={{marginRight:2}}/>
                                    {buttonName}
                                </Buttons.DarkBlueButton>
                            ) :
                                (<Buttons.LightBlueButton key={index} sx={{ boxShadow: "0px 0px 2px 5px", borderRadius: "8px!important" }} onClick={() => btnClick(index)}>
                                    {/* <ButtonCount>{index + 1}</ButtonCount> */}
                                    <img src={`/assets/images/icon${index+1}.png`} style={{marginRight:2}}/>
                                    {buttonName}
                                </Buttons.LightBlueButton>)
                            }
                            </>
                        )}
                    </Grid>
                </Grid>
            </RoundedContainer>
        </>
    )
}
