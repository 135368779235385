import React, { useEffect, useState } from 'react'
import { Box } from '@mui/system'
import { Button, Grid, Typography } from '@mui/material'
import styled from '@emotion/styled'
import StepWrapper from '../../stepwrapper'
import CustomHeader from '../customheader'
import ErrorText from '../../ErrorText'
import CustomFooter from '../customfooter'
import { updateProjectData } from '../../../controllers/ProjectController'
import PageLoader from '../../pageLoader'

const BoxWrapper = styled(Button)`
display: flex;
flex-direction: column; 
align-items: center;
width: 100%;
height: 100%;
display: flex;
justify-content: space-between;
color:black;
`
export default function Step2({ data, setData, updateStep, activeStep, projectId }) {
    const height = window.innerHeight;
    const [stateChanged, setStateChanged] = useState(false)
    const [mobileSelectedIndex, setMobileSelectedIndex] = useState(null)
    const [desktopSelectedIndex, setDesktopSelectedIndex] = useState(null)
    const [updatedData, setUpdatedData] = useState({})
    const [loading2, setLoading2] = useState(true);

    const imagesLayouts = [
        {
            width: 1200,
            height: 680,
            title: 'Apaisado',
            img: '/assets/images/step2/Apaisado.png'
        },
        {
            width: 1080,
            height: 1920,
            title: 'Extenso',
            img: '/assets/images/step2/Extenso.png'
        },
        {
            width:1080,
            height:1350,
            title:'Clásico',
            img:'/assets/images/step2/Clasico.png'
        },
        {
            width:1080,
            height:1080,
            title:'Cuadrado',
            img:'/assets/images/step2/cuadrado.png'
        },
        {
            width:1000,
            height:1500,
            title:'Rectangular',
            img:'/assets/images/step2/rectangular.png'
        }
    ]


    useEffect(() => {
        console.log(data, 'data')
        if (data.mobileDimensions?.height && data.desktopDimensions?.height) {
            if (data.mobileDimensions) {
                imagesLayouts.map((item, index) => {
                    if (item.height === data.mobileDimensions.height && item.width === data.mobileDimensions.width) {
                        setMobileSelectedIndex(index)
                        console.log(index, 'iii')
                        setLoading2(false)
                    }
                })

            }
            if (data.desktopDimensions) {
                imagesLayouts.map((item, index) => {
                    if (item.height === data.desktopDimensions.height && item.width === data.desktopDimensions.width) {
                        setDesktopSelectedIndex(index)
                        console.log(index, 'iii')
                        setLoading2(false)
                    }
                })

            }
        }
        else {
            setLoading2(false)
        }

    }, [data])

    const handleChange = (item, index, type) => {
        console.log(item, 'handle change')
        if (type === 'mobile') {
            setData({ ...data, mobileDimensions: { height: item.height, width: item.width } })
            setUpdatedData({ ...updatedData, mobileDimensions: { height: item.height, width: item.width } })
            setMobileSelectedIndex(index)
            setStateChanged(true)
        }
        else {
            setData({ ...data, desktopDimensions: { height: item.height, width: item.width } })
            setUpdatedData({ ...updatedData, desktopDimensions: { height: item.height, width: item.width } })
            setDesktopSelectedIndex(index)
            setStateChanged(true)
        }
    }

    const nextStepClick = async () => {
        if (validate() === true) {
            if (stateChanged) {
                setLoading2(true);
                await updateProjectData(updatedData, projectId)
                updateStep(activeStep + 1)
                setLoading2(false)
                //move to next step
            }
            else {
                setLoading2(true)
                // move to next step
                updateStep(activeStep + 1)
                setLoading2(false)
            }
        }
    }

    const backToFirstStep = () => {
        updateStep(activeStep - 1)
    }
    const validate = () => {
        if (mobileSelectedIndex >= 0 && desktopSelectedIndex >= 0) {
            return true
        } else {
            return false
        }
    }

    return (
        <>
            {loading2 === true &&
                <PageLoader />
            }
            <Box sx={{
                mt: { md: `calc(${height}px - ${height - 60}px)`, xs: 2 },
                mb: `calc(${height}px - ${height - 55}px)`,
                overflowY: { md: "hidden", xs: "auto" },
                height: "100%",
            }}>
                <CustomHeader
                    title="Elija Formato"
                    description="Seleccione el tamaño para el uso de su diseño"
                />
                <StepWrapper style2={{ overflowY: 'auto', my: 2 }}>
                    <Grid my={1} container fullWidth>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ pr: { md: "90px", xs: 0 }, textAlign: { xs: "center", md: "left" } }}>
                                <Typography varient="h5" sx={{
                                    fontSize: "20px",
                                    fontWeight: 700,
                                    lineHeight: 1,
                                    pb: 2
                                }}>Formatos para redes sociales</Typography>
                                <Typography pb={2} varient="subtitle1">Tamaños habituales al visualizarse en móvil.</Typography>
                                <Box sx={{ display: "flex", gap: "10px", justifyContent: { xs: "center", md: "flex-start" } }}>
                                    <img src="/assets/images/google Ads.png" alt='' />
                                    <img src="/assets/images/linkdin.png" alt='' />
                                    <img src="/assets/images/facebook.png" alt='' />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Box sx={{ display: "flex", flexWrap: "wrap" }}>

                                {imagesLayouts.map((item, index) => (
                                    <Box key={index + 'size'} sx={{ width: { md: "20%", xs: "100%" }, px: { md: 2, xs: 0 }, py: { md: 0, xs: 2 }, height: { md: "calc(150px + 5vh)", xs: "160px" } }}>
                                        <Box sx={{ background: `${desktopSelectedIndex === index ? "lightblue" : "white"}`, borderRadius: "16px", overflow: "hidden", height: "100%" }}>
                                            <BoxWrapper onClick={() => handleChange(item, index, 'desktop')}>
                                                <Box sx={{ borderRadius: "5px", p: 1, maxHeight: "135px" }}>
                                                    <img src={item.img} width="70%" alt='' />
                                                </Box>
                                                <Box sx={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                                                    <Typography variant='subtitle2' sx={{ textTransform: "none", fontWeight: 600 }}>{item.title}</Typography>
                                                    <Typography>{item.width} x {item.height}</Typography>
                                                </Box>
                                            </BoxWrapper>
                                        </Box>
                                    </Box>
                                ))}
                                <ErrorText show={!data.desktopDimensions.height}>Please Select an image layout.</ErrorText>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ width: "full", height: "3px", mt: 1, borderRadius: "16px", background: "linear-gradient(45deg, #e7c29f, #e6666c)" }} ></Box>
                    <Grid my={1} container fullWidth>
                        <Grid item xs={12} md={3}>
                            <Box sx={{ pr: { md: "90px", xs: 0 }, textAlign: { xs: "center", md: "left" } }}>
                                <Typography varient="h5" sx={{
                                    fontSize: "20px",
                                    fontWeight: 700,
                                    lineHeight: 1,
                                    pb: 2
                                }}>Formatos para redes sociales</Typography>
                                <Typography pb={2} varient="subtitle1">Tamaños habituales al visualizarse en móvil.</Typography>
                                <Box sx={{ display: "flex", gap: "10px", justifyContent: { xs: "center", md: "flex-start" } }}>
                                    <img src="/assets/images/google Ads.png" alt='' />
                                    <img src="/assets/images/linkdin.png" alt='' />
                                    <img src="/assets/images/facebook.png" alt='' />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Box sx={{ display: "flex", flexWrap: "wrap" }}>

                                {imagesLayouts.map((item, index) => (
                                    <Box key={index + 'size'} sx={{ width: { md: "20%", xs: "100%" }, px: { md: 2, xs: 0 }, py: { md: 0, xs: 2 }, height: { md: "calc(150px + 5vh)", xs: "160px" } }}>
                                        <Box sx={{ background: `${mobileSelectedIndex === index ? "lightblue" : "white"}`, borderRadius: "16px", overflow: "hidden", height: "100%" }}>
                                            <BoxWrapper onClick={() => handleChange(item, index, 'mobile')}>
                                                <Box sx={{ borderRadius: "5px", p: 1, maxHeight: "135px" }}>
                                                    <img src={item.img} width="70%" alt='' />
                                                </Box>
                                                <Box sx={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                                                    <Typography variant='subtitle2' sx={{ textTransform: "none", fontWeight: 600 }}>{item.title}</Typography>
                                                    <Typography>{item.width} x {item.height}</Typography>
                                                </Box>
                                            </BoxWrapper>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                            <ErrorText show={!data.mobileDimensions.height}>Please Select an image layout.</ErrorText>
                        </Grid>
                    </Grid>
                </StepWrapper>
            </Box>
            <CustomFooter
                movePreviousStep={backToFirstStep}
                nextStep={nextStepClick}
                activeStep={activeStep}
                updateStep={updateStep}
                create={false}
            />
        </>
    )
}