import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import React, { useCallback, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import RoundedContainer from '../../roundedcontainer'
import Buttons from '../../../atoms/buttons/Button'
import RoundedNextContainer from '../../roundednextcontainer'
import { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import SearchModal from '../searchImage/searchModal'
import getCroppedImg from '../../../utils/cropImage'
import CropModal from '../cropModal'



export default function DataImage({ data, setData, handleChange }) {
    const [active, setActive] = useState(0);
    const [preview, setPreview] = useState(null)
    const [base64Image, setBase64Image] = useState({})
    console.log("base64Image: ", base64Image);
    const [open, setOpen] = useState(false)
    const [fileUrl, setFileUrl] = useState(null)

    const [croppedPixel, setCroppedPixel] = useState(null);
    const [cropImage, setCropImage] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [cropOpen, setCropOpen] = useState(false);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedPixel(croppedAreaPixels)
        console.log(croppedArea, 'cccc', croppedAreaPixels, 'ccc', crop)
    }, [])

    const saveImage = async () => {
        const CRImage = await getCroppedImg(base64Image, croppedPixel)
        console.log(CRImage, 'cimage')
        handleChange('image', CRImage)
        setBase64Image(CRImage);
        setCropOpen(false)
    }

    const ChangeImage = (file) => {
        const { files } = file.target
        if (files && files.length !== 0) {
          setFileUrl(files[0])
        }
      }

      useEffect(() => {
        let fileReader, isCancel = false;
        if (fileUrl) {
          fileReader = new FileReader();
          fileReader.onload = (e) => {
            const { result } = e.target;
            console.log(result)
            if (result && !isCancel) {
                setBase64Image(result)
                setCropOpen(true)
              setData({ ...data, image: result })
            }
          }
          fileReader.readAsDataURL(fileUrl);
        }
        return () => {
          isCancel = true;
          if (fileReader && fileReader.readyState === 1) {
            fileReader.abort();
          }
        }
      }, [fileUrl]);

    useEffect(() => {
        console.log(data.image,'imaggg')
        setBase64Image(data.image)
    }, [data.image])

    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => setOpen(false);


    const selectImage = (img) => {
        setBase64Image(img);
        setCropOpen(true)
        setData({ ...data, hasUrl: true, imgSrc: img, image: img })
        handleClose();
    }

    return (
        <>
            <Box>
                <Grid sx={{ rowGap: "14px" }} container>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ mr: { xs: 0, md: 1 }, height: "100%" }}>
                            <RoundedContainer>
                                <Typography sx={{ fontSize: "15px" }} >Agregue imagen para el diseño</Typography>
                                <Box sx={{ position: "relative", display: "grid", justifyContent: 'center', paddingTop: "20px" }}>
                                    <img src={base64Image ? base64Image : "/assets/images/notfound.webp"} alt=''
                                        style={{ borderRadius: "8px", width: "75px", height: "75px", marginBlock: "10px" }} />
                                </Box>
                                <Typography sx={{ display: "flex", justifyContent: "center", paddingTop: "10px", fontSize: "15px" }} >Arrastre y suelte su imagen</Typography>
                                <Box sx={{ display: "flex", justifyContent: "center", paddingTop: "10px" }} >
                                    <Buttons.OrangeButton >
                                        <input accept="image/*"
                                            style={{
                                                position: "absolute",
                                                top: 0,
                                                right: 0,
                                                width: "100%",
                                                height: "100%",
                                                margin: 0,
                                                fontSize: "23px",
                                                cursor: "pointer",
                                                opacity: 0,
                                                direction: "ltr",
                                            }}
                                            className="input"
                                            id="icon-button-file"
                                            type="file"
                                            name='...'
                                            onChange={ChangeImage} />
                                        <Typography sx={{ fontSize: "25px" }} >Adjunte un archivo</Typography>
                                    </Buttons.OrangeButton>
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "space-between", justifyItems: "center", fontSize: "10px", paddingTop: "10px" }} >
                                </Box>
                            </RoundedContainer>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ ml: { xs: 0, md: 1 } }}>
                            <RoundedContainer style={{ marginbottom: "10px", width: "100%" }} >
                                <Typography sx={{ fontSize: "15px", textAlign: { xs: "center", md: "start" } }} >Seleccione imagen de stock</Typography>
                                <Box sx={{ marginTop: "20px" }} >
                                    <Box sx={{ paddingBottom: "14px" }}>
                                        <RoundedNextContainer style={{}}>
                                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                                                <Box sx={{ width: "21.99px", height: "20px" }} > <img src='/assets/images/unsplash.svg' alt='' /></Box>
                                                <Typography sx={{ color: "#4D4D4D" }} >Unsplash</Typography>
                                                <Buttons.RedButton onClick={handleOpen} sx={{ display: "flex", justifyContent: "center", justifyItems: "center", gap: 1 }}>
                                                    <Typography sx={{ fontSize: "12px" }} >Buscar</Typography>
                                                    <SearchIcon fontSize='12px' />
                                                </Buttons.RedButton>
                                            </Box>
                                        </RoundedNextContainer>
                                    </Box>

                                    <Box sx={{ paddingBottom: "14px" }} >
                                        <RoundedNextContainer sx={{ marginTop: "10px" }} >
                                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                                                <Box sx={{ width: "21.99px", height: "20px" }} > <img src='/assets/images/pixabay.svg' alt='' /></Box>
                                                <Typography sx={{ color: "#4D4D4D" }}>Pixabay</Typography>
                                                <Buttons.RedButton sx={{ display: "flex", justifyContent: "center", justifyItems: "center", gap: 1 }}>
                                                    <Typography sx={{ fontSize: "10px" }} >Buscar</Typography>
                                                    <SearchIcon fontSize="12px" />
                                                </Buttons.RedButton>
                                            </Box>
                                        </RoundedNextContainer>
                                    </Box>
                                </Box>
                            </RoundedContainer>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <SearchModal open={open} setOpen={setOpen} handleClose={handleClose} selectImage={selectImage} />
            <CropModal
                open={cropOpen}
                setOpen={setCropOpen}
                handleClose={() => setOpen(false)}
                crop={crop}
                setCrop={setCrop}
                zoom={zoom}
                setZoom={setZoom}
                onCropComplete={onCropComplete}
                image={base64Image}
                saveImage={saveImage}
                data={data}
            />
        </>
    )
}
