import React, { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import ImageResults from './ImageResult'
import Buttons from '../../../atoms/buttons/Button'
import Grid from '@mui/material/Grid'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    height: "80%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: "16px",
};

export default function SearchModal({ open,setOpen, handleClose, selectImage }) {
    const [SearchImage, setSearchImage] = useState("")
    const [image, setImage] = useState({
        amount: "20",
        apiKey: "35850022-74538c6c8f4f4ff2fb36c3e80",
        apiUrl: "https://pixabay.com/api/",
        images: []
    })

    useEffect(() => {
        if(open === true){
            onSearchButtonClick()
        }
    },[setOpen,open])

    const onSearchButtonClick = () => {
        fetch(
            `${image.apiUrl}/?key=${image.apiKey}&q=${SearchImage}&image_type=photo&per_page=${image.amount}&safesearch=true`
        )
            .then(res => res.json())
            .then(data => {
                console.log(data)
                setImage({
                    ...image,
                    images: data.hits
                })
            }
            )
            .catch(err => {
                console.log(err, 'err');
                alert(err)
            }
            );
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onSearchButtonClick()
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box sx={{ overflow: "hidden", height: "100%" }}>
                    <Typography sx={{ fontSize: "15px", fontWeight: "700", textAlign: "center", pb: 2 }} >Search</Typography>
                    <Grid container>
                        <Grid sx={{ display: "flex", justifyContent: "center" }} item xs={12} md={10}>
                            <TextField
                                name="searchText"
                                size='small'
                                value={SearchImage}
                                onChange={(e) => setSearchImage(e.target.value)}
                                label="Search for Images"
                                type="search"
                                sx={{ mt: 0, mb: 0 }}
                                margin="normal"
                                fullWidth={true}
                                onKeyDown={handleKeyDown}
                            />
                        </Grid>
                        <Grid sx={{ display: "flex", justifyContent: "center" }} item xs={12} md={2}>
                            <Buttons.LightBlueButton
                                variant="contained"
                                color="primary"
                                onClick={onSearchButtonClick}
                            >
                                Search
                            </Buttons.LightBlueButton>
                        </Grid>
                    </Grid>
                    {image.images.length > 0 ? (
                        <ImageResults images={image.images} selectImage={selectImage} />
                    ) : null}
                </Box>
            </Box>
        </Modal>
    )
}
