import { useEffect, useState } from "react";
import { urlToBase64 } from "../../utils/urlToBase64";

export default function Template1(props) {


   const [image,setImage] = useState();
   const [logo,setLogo] = useState();

   useEffect(() => {
    urlToBase64(setImage,props.data.image)
    urlToBase64(setLogo,props.data.logo)
   },[props.data.image])


    const calculateData = {
        height:props.data.desktopDimensions.height/props.division,
        width: props.data.desktopDimensions.width/props.division,
    }

    return (
        <>
        <div style={{
            width: calculateData.width,
            height: calculateData.height,
            backgroundImage: 'url(' +  image + ')',
            backgroundColor: '#ddd',
            padding: '20px',
            borderRadius: '12px',
            border: '1px solid #ddd',
            boxShadow: '1px 2px 5px 1px rgba(0,0,0,0.2)',
            position: 'relative',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'top center',
            textAlign: 'center',
            overflow:'hidden'
        }}>
            <h3 style={{ fontSize: calculateData.width/34, color: props.data.bgColor1, margin: '0px' }}>{props.data.imgSubtitle}</h3>
            <h1 style={{ fontSize: calculateData.width/17, color: props.data.bgColor2, margin: '10px 0px' }}>{props.data.imgTitle}</h1>
            <button style={{
                padding: calculateData.width/28,
                fontSize: calculateData.width/38,
                borderRadius: '12px',
                border: '0px',
                background: props.data.bgColor1,
                minWidth: 100,
                color: '#fff',
                fontWeight: '500'
            }}>{props.data.imgCtaText}</button>
            <img
                src={logo}
                style={{
                    width: calculateData.width/5,
                    height: calculateData.width/5,
                    borderRadius: '50%',
                    position: 'absolute',
                    bottom: '40px',
                    border: '3px solid #ddd',
                    left: 0, right: 0,
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }} alt="" />
        </div>
        </>

    )
}
