import React, { useCallback, useEffect, useRef } from 'react'
import { Box } from '@mui/system'
import { Grid, IconButton, Popover, TextField, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';

import RoundedNextContainer from '../../roundednextcontainer'
import Buttons from '../../../atoms/buttons/Button'
import RoundedContainer from '../../roundedcontainer'
import StepWrapper from '../../stepwrapper'
import CustomHeader from '../customheader'
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react'
import ErrorText from '../../ErrorText'
import CustomFooter from '../customfooter'
import { getGeneratedText, updateProjectData, uploadToFirebase } from '../../../controllers/ProjectController'
import SearchModal from '../../modal/searchImage/searchModal'
import PageLoader from '../../pageLoader'
import Template1 from '../../templates/template1';
import Template2 from '../../templates/template2'
import * as htmlToImage from 'html-to-image';
import CircleIcon from '@mui/icons-material/Circle';
import { ChromePicker } from 'react-color'
import Template3 from '../../templates/template3'
import Template4 from '../../templates/template4'
import Template5 from '../../templates/template5'
import CropModal from '../../modal/cropModal'
import getCroppedImg, { generateDownload } from '../../../utils/cropImage'

import useImageColor from 'use-image-color'
import RefreshIcon from '@mui/icons-material/Refresh';

const Text = ["Amigable", "Elegante", "Relajado", "Profesional", "Persuasivo", "Ingenioso", "Osado"]


export default function Step3({ data, setData, updateStep, activeStep, projectId }) {
    const height = window.innerHeight;

    const [active1, setActive1] = useState(0);
    const [preview, setPreview] = useState(null)

    const [stateChanged, setStateChanged] = useState(false)
    const [base64Image, setBase64Image] = useState()
    const [updatedData, setUpdatedData] = useState({})
    const [loading3, setLoading3] = useState(false)
    const [anchorEl1, setAnchorEl1] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [anchorEl3, setAnchorEl3] = useState(null);
    const [imagesLayout, setImageLayout] = useState(null);
    const [croppedPixel, setCroppedPixel] = useState(null);
    const [cropImage, setCropImage] = useState(null);

    const { colors } = useImageColor(data.logo, { cors: true, colors: 5 })
    const [refresh, setRefresh] = useState(true);
    const domEl = useRef();

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [cropOpen, setCropOpen] = useState(false);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedPixel(croppedAreaPixels)
        console.log(croppedArea, 'cccc', croppedAreaPixels, 'ccc', crop)
    }, [])

    const saveImage = async () => {
        const CRImage = await getCroppedImg(base64Image, croppedPixel)
        console.log(CRImage, 'cimage')
        handleChange('image', CRImage)
        setBase64Image(CRImage);
        setCropOpen(false)
    }

    const imagesLayouts = [
        {
            width: 1200,
            height: 680,
            title: 'Apaisado',
            img: '/assets/images/step2/Apaisado.png'
        },
        {
            width: 1080,
            height: 1920,
            title: 'Extenso',
            img: '/assets/images/step2/extenso.png'
        },
        {
            width: 1080,
            height: 1350,
            title: 'Clásico',
            img: '/assets/images/step2/Clásico.png'
        },
        {
            width: 1080,
            height: 1080,
            title: 'Cuadrado',
            img: '/assets/images/step2/Cuadrado.png'
        },
        {
            width: 1000,
            height: 1500,
            title: 'Rectangular',
            img: '/assets/images/step2/Rectangular.png'
        }
    ]

    function getMultipleRandom(arr, num) {
        const shuffled = [...arr].sort(() => 0.5 - Math.random());
        return shuffled.slice(0, num);
    }

    useEffect(() => {
        const layout = imagesLayouts.filter(item => item.width === data.desktopDimensions.width && item.height === data.desktopDimensions.height);
        setImageLayout(layout[0])
        console.log(data, 'data')

        setBase64Image(data?.image)
    }, [])

    useEffect(() => {
        console.log(data.image, 'imaggg')
        setBase64Image(data.image)
        if (data.textTonality) {
            setActive1(data.textTonality)
        }
    }, [data.image])

    useEffect(() => {
        if (colors !== undefined) {
            getColor();
        }
    }, [colors])

    const getColor = () => {
        setRefresh(true)

        const RandomColor = getMultipleRandom(colors, 3)
        console.log(RandomColor, colors, 'colors')
        setData({
            ...data,
            bgColor1: RandomColor[0],
            bgColor2: RandomColor[1],
            bgColor3: RandomColor[2],
        })
        setRefresh(false)
    }

    useEffect(() => {
        let fileReader, isCancel = false;
        if (data.imgSrc && !data.hasUrl) {
            fileReader = new FileReader();
            fileReader.onload = (e) => {
                const { result } = e.target;
                if (result && !isCancel) {
                    console.log(result)
                    setData({ ...data, hasUrl: false })
                    setCropOpen(true)
                    setBase64Image(result)
                }
            }
            fileReader.readAsDataURL(data.imgSrc);
        }
        else {
            if (data.imgSrc) {
                setBase64Image(data.imgSrc)
            }
            else {
                setBase64Image(data.image)
            }
        }
        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        }
    }, [data.imgSrc]);



    const handleColorClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
    };
    const handleColorClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleColorClick3 = (event) => {
        setAnchorEl3(event.currentTarget);
    };

    const handleClosePop = () => {
        setAnchorEl1(null);
        setAnchorEl2(null);
        setAnchorEl3(null);
    };


    const handleChange = (name, value) => {
        setUpdatedData({
            ...updatedData,
            [name]: value
        })
        setData({ ...data, [name]: value })
        setStateChanged(true)
    }
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    const openPop1 = Boolean(anchorEl1);
    const id1 = open ? 'simple-popover' : undefined;

    const openPop2 = Boolean(anchorEl2);
    const id2 = open ? 'simple-popover' : undefined;

    const openPop3 = Boolean(anchorEl3);
    const id3 = open ? 'simple-popover' : undefined;

    const nextStepClick = async () => {
        setLoading3(true)

        if (stateChanged) {
            const path = 'project/'+projectId + '/Image/'
            const imgBase64 = data.image
            const ImageUrl = await uploadToFirebase(path, imgBase64)
            console.log("ImageUrl: ", ImageUrl);
          
            delete updatedData.imgSrc
            const dataUrl = await htmlToImage.toPng(domEl.current);

            const previewPath = 'project/'+projectId + '/Preview/'
            const PreviewUrl = await uploadToFirebase(previewPath, dataUrl)

            setData({
                ...data,
                image: ImageUrl,
                adsImage: { image1: PreviewUrl }
            })

            await updateProjectData({
                ...updatedData,
                image: ImageUrl,
                adsImage: { image1: PreviewUrl },
                bgColor1: data.bgColor1,
                bgColor2: data.bgColor2,
                bgColor3: data.bgColor3
            }, projectId)

             updateStep(activeStep + 1)
            setLoading3(false)
            //move to next step
        }
        else {
            // move to next step
            updateStep(activeStep + 1)
            setLoading3(false)
        }
    }

    const backToSecondtStep = () => {
        updateStep(activeStep - 1)
    }

    const selectImage = (img) => {
        setLoading3(true)
        console.log(img);
        setBase64Image(img);
        setCropOpen(true)
        setData({ ...data, hasUrl: true, imgSrc: img, image: img })
        handleClose();
        setLoading3(false);
    }

    const handleColor = (color, colorFiels) => {
        setData({
            ...data,
            [colorFiels]: color,
        })
    }

    const genrateText = async() => {
        const requestData = {
            title: data.imgTitle,
            subtitle: data.imgSubtitle,
            cta: data.imgCtaText,
            // tone: data.textTonality
        }
        await getGeneratedText(requestData).then(res => {
            console.log(res,'ress')
            if(res.title && res.subtitle && res.cta){
                handleChange('imgTitle',res.title)
                handleChange('imgSubtitle',res.subtitle)
                handleChange('imgCtaText',res.cta)
            }
        })
    }

    return (
        <>
            {loading3 === true &&
                <PageLoader />
            }
            <Box sx={{
                mt: { md: `calc(${height}px - ${height - 80}px)`, xs: 2 },
                mb: `calc(${height}px - ${height - 55}px)`,
                overflowY: { md: "hidden", xs: "auto" },
                height: "100%",
            }}>

                <CustomHeader
                    title="Textos, CTA e imagen"
                    description="Rellene los campos con la idea básica que desea transmitir y la IA se encargará del resto"
                />
                <StepWrapper style2={{ overflowY: "auto", }}>
                    <Grid container sx={{ rowGap: 2, height: "100%", overflowY: { md: "auto", xs: "revert" } }}>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ mx: { md: 1, xs: 0 } }}>
                                <Box sx={{ height: "100%" }} >
                                    <RoundedContainer style={{ px: 1 }} >
                                        <Box my={0}>
                                            <RoundedContainer style={{ display: "flex" }}  >
                                                <Grid container >
                                                    <Grid item xs={12} md={2}>
                                                        <Typography sx={{ fontSize: "12px", textAlign: { xs: "center", md: "start" } }}>Tonalidad del texto</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} md={10}>
                                                        <Box >
                                                            <Box
                                                                sx={{
                                                                    display: "flex", flexWrap: "wrap",
                                                                    "& .MuiButtonBase-root": { m: 0.2, fontSize: "10px", color: "black" }
                                                                }}
                                                            >
                                                                {Text.map((text, index) =>
                                                                    <Box key={index}>{active1 === text ? (
                                                                        <Buttons.MaroonButton sx={{ my: 2, color: '#fff' }}>
                                                                            {text}
                                                                        </Buttons.MaroonButton>
                                                                    ) :
                                                                        (<Buttons.OrangeButton sx={{ my: 2 }}
                                                                            onClick={() => {
                                                                                setActive1(text)
                                                                                handleChange('textTonality', text)
                                                                            }}>
                                                                            {text}
                                                                        </Buttons.OrangeButton>)
                                                                    }
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                            <ErrorText show={data?.textTonality?.length <= 0}>Please select text tonality.</ErrorText>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </RoundedContainer>
                                            <Grid container sx={{ marginTop: 1, rowGap: 1 }} >
                                                <Grid item xs={12} md={8} >
                                                    <Box sx={{ mr: { xs: 0, md: 2 } }} >
                                                        <TextField
                                                            fullWidth
                                                            id="outlined-multiline-flexible"
                                                            placeholder='Título'
                                                            multiline
                                                            size='small'
                                                            maxRows={1}
                                                            value={data.imgTitle}
                                                            onChange={(e) => handleChange('imgTitle', e.target.value)}
                                                        />
                                                        <ErrorText show={data?.imgTitle?.length <= 0}>Please enter title.</ErrorText>

                                                        <TextField
                                                            sx={{ marginTop: 1 }}
                                                            fullWidth
                                                            id="outlined-multiline-flexible"
                                                            placeholder='Subtítulo, opcional'
                                                            multiline
                                                            size='small'
                                                            maxRows={1}
                                                            value={data.imgSubtitle}
                                                            onChange={(e) => handleChange('imgSubtitle', e.target.value)}
                                                        />
                                                        <ErrorText show={data?.imgSubtitle?.length <= 0}>Please enter punchline.</ErrorText>
                                                        <TextField

                                                            sx={{ marginTop: 1 }}
                                                            fullWidth
                                                            id="outlined-multiline-flexible"
                                                            placeholder='Call to Action, opcional' multiline
                                                            size='small'
                                                            maxRows={1}
                                                            value={data.imgCtaText}
                                                            onChange={(e) => handleChange('imgCtaText', e.target.value)}
                                                        />
                                                        <ErrorText show={data?.imgCtaText?.length <= 0}>Please enter cta.</ErrorText>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Box sx={{ ml: { xs: 0, md: 1 } }} >
                                                        <RoundedNextContainer style={{ padding: "3px" }}>
                                                            <Box sx={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }} >
                                                                <Typography >Idioma</Typography>
                                                                <IconButton size='small' sx={{ background: `${data.langugage === "spanish" ? "lightblue" : ""}` }} onClick={() => handleChange('langugage', "spanish")}>
                                                                    <img src='/assets/images/flag-spain.svg' alt='' />
                                                                </IconButton>
                                                                <IconButton size='small' sx={{ background: `${data.langugage === "english" ? "lightblue" : ""}` }} onClick={() => handleChange('langugage', "english")}>
                                                                    <img src='/assets/images/flags_uk.svg' alt='' />
                                                                </IconButton>
                                                            </Box>
                                                        </RoundedNextContainer>
                                                        <Box sx={{ marginTop: "24px", display: "flex", justifyContent: "center" }}>
                                                            <Buttons.RedButton sx={{ display: "flex", flexDirection: "column" }} onClick={() => genrateText()}>
                                                                <Box sx={{ height: "20px" }}>
                                                                    <img src='/assets/images/Group.svg' height="100%" alt='' />
                                                                </Box>
                                                                <Box>
                                                                    GENERAR TEXTOS CON IA
                                                                </Box>
                                                            </Buttons.RedButton>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </RoundedContainer>
                                    <Box sx={{ marginTop: 1 }}>
                                        <RoundedContainer style={{ px: 1 }} >
                                            <Box my={0}>
                                                <Grid sx={{ rowGap: "14px" }} container>
                                                    <Grid item xs={12} md={6}>
                                                        <Box sx={{ mr: { xs: 0, md: 1 }, height: "100%" }}>
                                                            <RoundedContainer>
                                                                <Typography sx={{ fontSize: "12px" }} >Agregue imagen para el diseño</Typography>
                                                                <Box sx={{ position: "relative", display: "grid", justifyContent: 'center', paddingTop: "20px" }}>
                                                                    <img src={base64Image ? base64Image : "/assets/images/notfound.webp"} alt=''
                                                                        style={{ borderRadius: "50%", width: "72px", height: "72px", marginBlock: "10px" }} />
                                                                    <input accept="image/*"
                                                                        style={{
                                                                            position: "absolute",
                                                                            top: 0,
                                                                            right: 0,
                                                                            width: "100%",
                                                                            height: "100%",
                                                                            margin: 0,
                                                                            fontSize: "23px",
                                                                            cursor: "pointer",
                                                                            opacity: 0,
                                                                            direction: "ltr",
                                                                        }}
                                                                        className="input"
                                                                        id="icon-button-file"
                                                                        type="file"
                                                                        name='...'
                                                                        onChange={(event) => handleChange('imgSrc', event.target.files[0])}
                                                                    />
                                                                </Box>
                                                                <Typography sx={{ display: "flex", justifyContent: "center", paddingTop: "10px", fontSize: "15px" }} >Arrastre y suelte su imagen</Typography>
                                                                <Box sx={{ display: "flex", justifyContent: "center", paddingTop: "10px" }} >
                                                                    <Buttons.OrangeButton onClick={() => setPreview(base64Image)}  >
                                                                        <Typography sx={{ fontSize: "25px" }} >Adjunte un archivo</Typography>
                                                                    </Buttons.OrangeButton>
                                                                </Box>
                                                                <Box sx={{ display: "flex", justifyContent: "space-between", justifyItems: "center", fontSize: "10px", paddingTop: "10px" }} >
                                                                </Box>
                                                            </RoundedContainer>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Box sx={{ ml: { xs: 0, md: 1 }, height: "100%" }}>
                                                            <RoundedContainer style2={{ height: "100%" }} style={{ marginBottom: "10px", width: "100%" }} >
                                                                <Typography sx={{ fontSize: "15px", textAlign: { xs: "center", md: "start" } }} >Seleccione imagen de stock</Typography>
                                                                <Box sx={{ marginTop: "20px" }} >
                                                                    <Box sx={{ paddingBottom: "14px" }}>
                                                                        <RoundedNextContainer style={{}}>
                                                                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                                                                                <Box sx={{ width: "20px", height: "20px" }} > <img src='/assets/images/unsplash.svg' alt='' width="100%" /></Box>
                                                                                <Typography sx={{ color: "#4D4D4D" }} >Unsplash</Typography>
                                                                                <Buttons.RedButton onClick={handleOpen} sx={{ display: "flex", justifyContent: "center", justifyItems: "center", gap: 1 }}>
                                                                                    <Typography sx={{ fontSize: "12px" }} >Buscar</Typography>
                                                                                    <SearchIcon fontSize='12px' />
                                                                                </Buttons.RedButton>
                                                                            </Box>
                                                                        </RoundedNextContainer>
                                                                    </Box>
                                                                    <Box sx={{ paddingBottom: "14px" }} >
                                                                        <RoundedNextContainer sx={{ marginTop: "10px" }} >
                                                                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                                                                                <Box sx={{ width: "20px", height: "20px" }} > <img src='/assets/images/pixabay.svg' width="100%" alt='' /></Box>
                                                                                <Typography sx={{ color: "#4D4D4D" }}>Pixabay</Typography>
                                                                                <Buttons.RedButton onClick={handleOpen} sx={{ display: "flex", justifyContent: "center", justifyItems: "center", gap: 1 }}>
                                                                                    <Typography sx={{ fontSize: "12px" }} >Buscar</Typography>
                                                                                    <SearchIcon fontSize="12px" />
                                                                                </Buttons.RedButton>
                                                                            </Box>
                                                                        </RoundedNextContainer>
                                                                    </Box>
                                                                </Box>
                                                            </RoundedContainer>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </RoundedContainer>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ mx: { md: 1, xs: 0 }, height: "100%" }}>
                                <Box sx={{ marginBottom: "50px", height: "100%", display: "flex", flexDirection: "column" }}>
                                    <RoundedNextContainer style={{ height: "auto", padding: "0",minHeight: '350px' }} >
                                        <Box sx={{ backgroundColor: "#FFB979", height: "40px", width: "100%", borderTopRightRadius: "10px", borderTopLeftRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Typography>VISTA PREVIA</Typography>
                                        </Box>
                                        <Box sx={{ padding: 5, display: 'flex', justifyContent: 'center' }}>
                                            {/* {preview === null ?
                                            <Box sx={{ backgroundColor: "#B3B3B3", padding: "50px" }}>
                                                <Box>
                                                    <Box sx={{ display: "flex" }} >
                                                        <Box  >
                                                            <img src='/assets/images/image icon.svg' alt='' />
                                                        </Box>
                                                        <Box sx={{ marginLeft: "14px", marginTop: "10px" }} >
                                                            <img src='/assets/images/text icon.svg' alt='' />
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{ marginTop: "10px" }} >
                                                        <Box sx={{ display: "flex" }} >
                                                            <Box  >
                                                                <img src='/assets/images/image icon.svg' alt='' />
                                                            </Box>
                                                            <Box sx={{ marginLeft: "14px", marginTop: "10px" }} >
                                                                <img src='/assets/images/text icon.svg' alt='' />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            :
                                            <Box sx={{ maxHeight: "375px", overflow: "hidden" }}>
                                                <img src={preview} width="100%" alt='' />
                                            </Box>
                                        } */}
                                            <div ref={domEl} sx={{ backgroundColor: '#ddd', borderRadius: '4px', width: data.desktopDimensions.width / 4.5, height: data.desktopDimensions.height / 4.5, alignSelf: 'center' }}>
                                                {/* <Template1
                                                    data={data}
                                                    image={base64Image}
                                                    division={2.5}
                                                /> */}
                                                {/* <Template2
                                                data={data}
                                                image={base64Image}
                                                division={2.5}
                                            /> */}
                                                {/* <Template3
                                                    data={data}
                                                    image={base64Image}
                                                    division={2.5}
                                                /> */}
                                                <Template1
                                                    data={data}
                                                    image={base64Image}
                                                    division={4.5}
                                                />
                                                {/* <Template5
                                                data={data}
                                                image={data.image}
                                                division={2.5}
                                            /> */}
                                            </div>
                                        </Box>
                                    </RoundedNextContainer>
                                    <Box sx={{ display: "flex", justifyContent: "center", justifyItems: "center", fontSize: "10px", paddingTop: "10px" }} >
                                        <RoundedNextContainer style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            p: 1,
                                            alignItems: "center",
                                            width: "100px"
                                        }}>
                                            <IconButton size='small' onClick={handleColorClick1}>
                                                <CircleIcon sx={{ color: data.bgColor1, fontSize: "17px" }} />
                                            </IconButton>
                                            <Popover
                                                id={id1}
                                                open={openPop1}
                                                anchorEl={anchorEl1}
                                                onClose={handleClosePop}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <ChromePicker
                                                    color={data.bgColor1}
                                                    onChange={(updateColor) =>
                                                        handleColor(updateColor.hex, 'bgColor1')
                                                    }
                                                />
                                            </Popover>
                                            <Box component="span">
                                                {data.bgColor1}
                                            </Box>

                                        </RoundedNextContainer>
                                        <RoundedNextContainer style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            p: 1,
                                            alignItems: "center",
                                            mx: 2,
                                            width: "100px"
                                        }}>
                                            <IconButton size='small' onClick={handleColorClick2}>
                                                <CircleIcon sx={{ color: data.bgColor2, fontSize: "17px" }} />
                                            </IconButton>
                                            <Popover
                                                id={id2}
                                                open={openPop2}
                                                anchorEl={anchorEl2}
                                                onClose={handleClosePop}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <ChromePicker
                                                    color={data.bgColor2}
                                                    onChange={(updateColor) =>
                                                        handleColor(updateColor.hex, 'bgColor2')
                                                    }
                                                />
                                            </Popover>
                                            <Box component="span">
                                                {data.bgColor2}
                                            </Box>
                                        </RoundedNextContainer>
                                        <RoundedNextContainer style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            p: 1,
                                            alignItems: "center",
                                            width: "100px"
                                        }}>
                                            <IconButton size='small' onClick={handleColorClick3}>
                                                <CircleIcon sx={{ color: data.bgColor3, fontSize: "17px" }} />
                                            </IconButton>
                                            <Popover
                                                id={id3}
                                                open={openPop3}
                                                anchorEl={anchorEl3}
                                                onClose={handleClosePop}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <ChromePicker
                                                    color={data.bgColor3}
                                                    onChange={(updateColor) =>
                                                        handleColor(updateColor.hex, 'bgColor3')
                                                    }
                                                />
                                            </Popover>
                                            <Box component="span">
                                                {data.bgColor3}
                                            </Box>
                                        </RoundedNextContainer>
                                        <RoundedNextContainer style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            p: 1,
                                            alignItems: "center",
                                            width: "48px",
                                            marginLeft: 2,
                                        }}>
                                            <Box onClick={() => getColor(colors, 3)}>
                                                {refresh === true ?
                                                    <CircularProgress color='primary' size={18} />
                                                    :
                                                    <RefreshIcon color='primary' size={28} />
                                                }
                                            </Box>
                                        </RoundedNextContainer>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </StepWrapper>
            </Box>
            <CustomFooter
                nextStep={nextStepClick}
                movePreviousStep={backToSecondtStep}
                activeStep={activeStep}
                updateStep={updateStep}
                create={false}
            />
            <SearchModal open={open} setOpen={setOpen} handleClose={handleClose} selectImage={selectImage} />
            <CropModal
                open={cropOpen}
                setOpen={setCropOpen}
                handleClose={() => setOpen(false)}
                crop={crop}
                setCrop={setCrop}
                zoom={zoom}
                setZoom={setZoom}
                onCropComplete={onCropComplete}
                image={base64Image}
                saveImage={saveImage}
                data={data}
            />

        </>
    )
}