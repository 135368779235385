import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { urlToBase64 } from "../../utils/urlToBase64";

export default function Template5(props) {

    const [image,setImage] = useState();
    const [logo,setLogo] = useState();

    useEffect(() => {
     urlToBase64(setImage,props.data.image)
     urlToBase64(setLogo,props.data.logo)
    },[props.data.image])

    const calculateData = {
        height:props.data.desktopDimensions.height/props.division,
        width: props.data.desktopDimensions.width/props.division,
    }
    return (
        <div style={{
            width: calculateData.width,
            height: calculateData.height,
            backgroundColor: '#ddd',
            padding: '20px',
            borderRadius: '12px',
            border: '1px solid #ddd',
            boxShadow: '1px 2px 5px 1px rgba(0,0,0,0.2)',
            position: 'relative',
            overflow: 'hidden',
            textAlign: 'start',
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center"
        }}>
            <Box sx={{ height: "auto" }}>
                <img
                    src={logo}
                    style={{
                        width: calculateData.width/5,
                        height: calculateData.width/5,
                        borderRadius: '50%',
                        border: '3px solid #ddd',
                        left: 0, right: 0,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginBottom: 2
                    }} alt="" />
            </Box>
            <Box sx={{
                textAlign: 'center',
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
            }}>
                <Box sx={{
                    border: "2px solid",
                    borderColor: props.data.bgColor1,
                    borderRadius: "50%",
                    p: "5px",
                    position: 'relative',

                }}>
                    <Box sx={{
                        border: "3px",
                        borderStyle: "solid",
                        borderColor: "#5954e7",
                        borderRadius: "50%",
                        p: "3px"
                    }}>
                        <Box sx={{
                            aspectRatio: "1",
                            backgroundImage: 'url(' + image + ')',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            borderRadius: "50%",
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <h1 style={{ fontSize: calculateData.width/17, color: props.data.bgColor2, margin: '10px 0px' }}>{props.data.imgTitle}</h1>
                            <Box sx={{
                                position: "absolute",
                                bottom: 0,
                                right: 0,
                            }}>
                                <Box sx={{
                                    borderRadius: "50%",
                                    p: "10px",
                                    background: props.data.bgColor1,
                                }}>
                                    <Button style={{
                                        fontSize: calculateData.width/38,
                                        borderRadius: '50%',
                                        border: "1px solid white",
                                        width: calculateData.width/3.5,
                                        height: calculateData.width/3.5,
                                        color: '#fff',
                                        fontWeight: '500',
                                        zIndex: 99
                                    }}>{props.data.imgCtaText}</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ textAlign: "center", height: "auto",marginTop:2 }}>
                <h3 style={{ fontSize: calculateData.width/34, color: props.data.bgColor1, margin: '0px' }}>{props.data.imgSubtitle}</h3>
              
            </Box>
        </div >
    )
}
