import React, { useState } from 'react'
import Box from '@mui/system/Box'
import RoundedContainer from '../../components/roundedcontainer'
import { CircularProgress, Typography } from '@mui/material'
import { TextField } from '@mui/material'
import Buttons from '../../atoms/buttons/Button'
import { createNewUserAccount } from '../../controllers/AuthController'
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Checkbox from '@mui/material/Checkbox';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useRecoilState } from 'recoil';
import { userState } from '../../recoil/atom/userState'

const Register = () => {
    const height = window.innerHeight;
    const navigate = useNavigate()
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [verifyPassword, setVerifyPassword] = useState('')
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [user,setUser] = useRecoilState(userState)

    const handleRegister = async () => {
        if (email && password && verifyPassword && firstName && lastName) {
            if (password === verifyPassword) {
                if (loading === false) {
                    setLoading(true)
                    const data = {
                        name: firstName + '' + lastName,
                        email: email,
                        password: password,
                    }
                    const result = await createNewUserAccount(data)
                    if (result.success) {
                        setUser(localStorage.setItem('user',JSON.stringify(result.data)))
                        toast("User added successfully", { type: 'success' });
                        navigate('/user/dashboard')
                        setLoading(false)
                    }
                    else {
                        toast("please fill all fields", { type: 'error' });
                        setLoading(false)
                    }
                }
            }
            else {
                toast("password do not match", { type: 'error' });
            }
        }
        else {
            console.log("please fill all fields")
            toast("please fill all fields", { type: 'error' });
        }
    }


    return (
        <>
            <RoundedContainer>
                <Box sx={{ height: `calc(${height}px - 100px)`, position: "relative", overflowY: "auto", paddingBlock: "70px", boxSizing: "border-box" }}>
                    <Box sx={{
                        width: { md: "25%", xs: "100%" },
                        marginInline: "auto",
                    }}>
                        <Box sx={{ width: "100%", height: "100%" }}>
                            <Typography sx={{ textAlign: "center", mb: "40px", fontSize: "19px", fontWeight: 600 }}>Cree su cuenta Neurosuggest</Typography>
                            <Box>
                                <Typography variant='subtitle2' mb={1}>Nombre</Typography>
                                <TextField
                                    fullWidth
                                    sx={{ mb: "10px" }}
                                    required
                                    id="outlined-required"
                                    size="small"
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                                <Typography variant='subtitle2' mb={1}>Apellidos</Typography>
                                <TextField
                                    fullWidth
                                    sx={{ mb: "10px" }}
                                    required
                                    id="outlined-required"
                                    size="small"
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                                <Typography variant='subtitle2' mb={1}>E-mail</Typography>
                                <TextField
                                    fullWidth
                                    sx={{ mb: "10px" }}
                                    required
                                    id="outlined-required"
                                    size="small"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <Typography variant='subtitle2' mb={1}>Contraseña</Typography>
                                <TextField
                                    fullWidth
                                    sx={{ mb: "10px" }}
                                    required
                                    id="outlined-required"
                                    size="small"
                                    type='password'
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <Typography variant='subtitle2' mb={1}>Verificar contraseña </Typography>
                                <TextField
                                    fullWidth
                                    required
                                    sx={{ mb: "10px" }}
                                    id="outlined-required"
                                    size="small"
                                    type='password'
                                    onChange={(e) => setVerifyPassword(e.target.value)}
                                />
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "end", mb: "20px" }}>
                                <Checkbox onChange={(e) => setChecked(e.target.checked)} icon={<RadioButtonUncheckedIcon />} checkedIcon={<CheckCircleIcon />} />
                                <Typography variant='caption' sx={{ textAlign: "left" }}>Para continuar debe aceptar nuestra Política de Privacidad y Cesión de datos personales, que puede leer <Box component="span" sx={{ fontWeight: 700 }}>AQUÍ.</Box> </Typography>
                            </Box>
                            <Box sx={{ mb: "40px", display: "flex", justifyContent: "space-between" }}>
                                <Buttons.DisableButton variant="outlined" onClick={() => navigate("/")}>Atrás</Buttons.DisableButton>
                                {checked ? <Buttons.MaroonButton sx={{ opacity: loading ? 0.6 : 1 }} type='button' onClick={handleRegister} variant="contained">
                                    Registrarse
                                </Buttons.MaroonButton> :
                                    <Buttons.DisableButton sx={{ opacity: loading ? 0.6 : 1 }} type='button' disableElevation variant="contained">
                                        Registrarse
                                    </Buttons.DisableButton>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </RoundedContainer>
        </>
    )
}

export default Register;
