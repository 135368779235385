import { Typography } from "@mui/material";

export default function ErrorText({ children, show }) {
    if(show === true){
    return (
        <Typography color={'red'} fontSize={'12px'}>
        {children}
        </Typography>
    )
        }
        else{
            return(
                <></>
            )
        }
}
