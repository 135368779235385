import React, { useState } from 'react'
import Box from '@mui/system/Box'
import styled from '@emotion/styled'
import RoundedContainer from '../../components/roundedcontainer'
import { Typography } from '@mui/material'
import { TextField } from '@mui/material'
import { Button } from '@mui/material'
import Buttons from '../../atoms/buttons/Button'
import { resetPassword } from '../../controllers/AuthController'
import { useNavigate } from 'react-router-dom'

const CenterBox = styled(Box)`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width:30%;
        @media (max-width: 769px) {
        width: 100%;
        }
`

const ResetPasswordPage = () => {
    const [email, setEmail] = useState("")
    const navigate = useNavigate()

    const VerifyEmail = async () => {
        await resetPassword(email)
    }

    return (
        <>
            <RoundedContainer>
                <Box sx={{ height: "80vh", position: "relative", boxSizing: "border-box" }}>
                    <CenterBox>
                        <Typography sx={{ marginTop: "20px", paddingLeft: "30px" }} >¿No puedes iniciar sesión?</Typography>
                        <Typography sx={{ marginTop: "20px", paddingLeft: "25px" }} >Enviaremos un enlace de recuperación a</Typography>
                        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", mb: "10px" }}>
                            <TextField
                                sx={{ marginTop: "5px", textAlign: "left", paddingLeft: "", overflow: "hidden" }}
                                fullWidth
                                variant='outlined'
                                placeholder='E-mail'
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <Typography sx={{ textAlign: "center", }} variant='subtitle2'></Typography>
                            <Box sx={{ justifyContent: "center", display: "flex" }} >
                                <Buttons.RedButton onClick={VerifyEmail} sx={{ marginTop: "50px", marginBottom: "80px", padddingTop: "30px", }} > Enviar enlace de recuperación</Buttons.RedButton>
                            </Box>
                            <Box sx={{ width: "full", height: "1px", background: "#000", mb: 2 }}></Box>
                            <Button sx={{ color: "red", fontWeight: 700, textTransform: "none" }} onClick={() => navigate("/")} type='text'>Volver a Inicio de sesión</Button>
                        </Box>
                    </CenterBox>
                </Box>
            </RoundedContainer>
        </>
    )
}

export default ResetPasswordPage;
