import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/system'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
// import RoundedNextContainer from '../../roundednextcontainer'
// import Buttons from '../../../atoms/buttons/Button'
// import RoundedContainer from '../../roundedcontainer'
import StepWrapper from '../../stepwrapper'
import CustomHeader from '../customheader'
import CompareFooter from '../comparefooter'
import GradeIcon from '@mui/icons-material/Grade';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CreateIcon from '@mui/icons-material/Create';
import EditModal from '../../modal/editModal'
import CustomFooter from '../customfooter'
import Template1 from '../../templates/template1'
import { getGenratedImages, updateProjectData } from '../../../controllers/ProjectController'
import Template4 from '../../templates/template4'
import Template3 from '../../templates/template3'
import Template2 from '../../templates/template2'
import Template5 from '../../templates/template5'
import * as htmlToImage from 'html-to-image';
import PageLoader from '../../pageLoader'
import { uploadToFirebase } from '../../../controllers/ProjectController'


export default function Step4({ data, setData, updateStep, activeStep, projectId }) {
    const [active, setActive] = useState(false)
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const height = window.innerHeight;
    const [template, setTemplate] = useState()
    const [loading, setLoading] = useState(false);

    useEffect(()=> {
        genrateImages();
    },[projectId])


    const nextStepClick = async () => {
         updateStep(activeStep + 1)
    }
    const backToThirdStep = () => {
        updateStep(activeStep - 1)
    }

    const domEl = useRef();

    const renderTemplate = () => {
        if (template === '1') {
            return <Template1 data={data} image={data.image} division={1} />
        }
        else if (template === '2') {
            return <Template2 data={data} image={data.image} division={1} />
        }
        else if (template === '3') {
            return <Template3 data={data} image={data.image} division={1} />
        }
        else if (template === '4') {
            return <Template4 data={data} image={data.image} division={1} />
        }
        else if (template === '5') {
            return <Template5 data={data} image={data.image} division={1} />
        }
    }

    

    const handleSelect = async (temp) => {
        setLoading(true);
        setTemplate(temp)
        setTimeout(async () => {

            console.log(data)

            const dataUrl = await htmlToImage.toJpeg(domEl.current);
            console.log(dataUrl,'dataUrl')

            const previewPath = 'project/'+projectId + '/Preview/'
            const PreviewUrl = await uploadToFirebase(previewPath, dataUrl)

            console.log(PreviewUrl,'PreviewUrl')

            setData({
                ...data,
                adsImage: { image1: PreviewUrl },
            })

            await updateProjectData({
                adsImage: { image1: PreviewUrl },
            }, projectId)
            console.log(activeStep)
            updateStep(activeStep + 1)
            setLoading(false);

        }, 2000)
    }

    const btnClick = (index) => {
        if(active === index){
            setActive("")
        }
        else{
        setActive(index)
        }
    }


    const genrateImages = async() => {
        const requestData = {
            project_id: projectId
        }
        await getGenratedImages(requestData).then(res => {
            console.log(res,'ress')
        })
    }

    return (
        <>
            {loading === true &&
                <PageLoader />
            }

            <Box sx={{
                mt: { md: `calc(${height}px - ${height - 80}px)`, xs: 2 },
                mb: `calc(${height}px - ${height - 55}px)`,
                // overflowY: { md: "hidden", xs: "auto" },
                overflow: 'auto',
                height: "100%",
            }}>
                <CustomHeader
                    title="Elija el diseño final"
                    description="Compare y elija entre los diseños el que más le convenga"
                />
                <div style={{ opacity: 0 }}>
                    {template &&
                        <div ref={domEl} style={{
                            position: 'absolute',
                            marginTop: '-50px',
                            zIndex: -100,
                            padding: '20px',
                            backgroundColor: 'transparent'
                        }}>
                            {renderTemplate()}
                        </div>
                    }
                </div>

                <StepWrapper style2={{overflowY: "auto" }}>
                    <Box>
                        <Grid sx={{ rowGap: 2 }} container>
                            {/* {myArr.map((item, index) => */}
                            <Grid item xs={12} md={3}>
                                <Box mr={2}>
                                    <Box sx={{ mx: 1, background: "white", borderRadius: "10px", overflow: "hidden" }}>
                                        <Box sx={{ width: "100%", background: "#FFB979", p: 1 }}>
                                            <Box sx={{ display: "flex", justifyContent: "center", columnGap: 1, alignItems: "center" }}>
                                                <GradeIcon sx={{ color: "#FF3E57", fontSize: "24px" }} />
                                                <Typography sx={{ color: "#FF3E57", fontSize: "14px", fontWeight: 600 }}>Efectividad 99/100</Typography>
                                            </Box>
                                        </Box>
                                        <Box m={2} sx={{ height: data.desktopDimensions.height/3.8, display: "flex", justifyContent: "center", overflow: 'auto' }}>
                                            {/* <img src={data?.adsImage?.image1 ? data?.adsImage?.image1 : "/assets/images/banner.svg"} height="100%" alt='' /> */}
                                            <Template1 data={data} image={data.image} division={3.8} />
                                        </Box>
                                        <Box sx={{ width: "100%" }}>
                                            <Grid container >
                                                <Grid item xs={6}>
                                                    <Box onClick={handleOpen} sx={{ width: "100%", background: "#FFB979", alignItems: "center", p: 1, display: "flex", justifyContent: "center", columnGap: 1, cursor: "pointer" }}>
                                                        <Typography sx={{ color: "#FF3E57", fontSize: "12px" }}>Edición rápida</Typography>
                                                        <CreateIcon sx={{ fontSize: "18px", color: "#FF3E57" }} />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Box onClick={() => handleSelect('1')} sx={{ width: "100%", background: "#FF3E57", alignItems: "center", p: 1, display: "flex", justifyContent: "center", columnGap: 1 }}>
                                                        <Typography sx={{ color: "white", fontSize: "12px" }}>Seleccionar</Typography>
                                                        <ArrowForwardIcon sx={{ fontSize: "18px", color: "white" }} />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Box mr={2}>
                                    <Box sx={{ mx: 1, background: "white", borderRadius: "10px", overflow: "hidden" }}>
                                        <Box sx={{ width: "100%", background: "#FFB979", p: 1 }}>
                                            <Box sx={{ display: "flex", justifyContent: "center", columnGap: 1, alignItems: "center" }}>
                                                <GradeIcon sx={{ color: "#FF3E57", fontSize: "24px" }} />
                                                <Typography sx={{ color: "#FF3E57", fontSize: "14px", fontWeight: 600 }}>Efectividad 99/100</Typography>
                                            </Box>
                                        </Box>
                                        <Box m={2} sx={{ height: data.desktopDimensions.height/3.8, display: "flex", justifyContent: "center", overflow: 'auto' }}>
                                            {/* <img src={data?.adsImage?.image1 ? data?.adsImage?.image1 : "/assets/images/banner.svg"} height="100%" alt='' /> */}
                                            <Template2 data={data} image={data.image} division={3.8} />
                                        </Box>
                                        <Box sx={{ width: "100%" }}>
                                            <Grid container >
                                                <Grid item xs={6}>
                                                    <Box onClick={handleOpen} sx={{ width: "100%", background: "#FFB979", alignItems: "center", p: 1, display: "flex", justifyContent: "center", columnGap: 1, cursor: "pointer" }}>
                                                        <Typography sx={{ color: "#FF3E57", fontSize: "12px" }}>Edición rápida</Typography>
                                                        <CreateIcon sx={{ fontSize: "18px", color: "#FF3E57" }} />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Box onClick={() => handleSelect('2')} sx={{ width: "100%", background: "#FF3E57", alignItems: "center", p: 1, display: "flex", justifyContent: "center", columnGap: 1 }}>
                                                        <Typography sx={{ color: "white", fontSize: "12px" }}>Seleccionar</Typography>
                                                        <ArrowForwardIcon sx={{ fontSize: "18px", color: "white" }} />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Box mr={2}>
                                    <Box sx={{ mx: 1, background: "white", borderRadius: "10px", overflow: "hidden" }}>
                                        <Box sx={{ width: "100%", background: "#FFB979", p: 1 }}>
                                            <Box sx={{ display: "flex", justifyContent: "center", columnGap: 1, alignItems: "center" }}>
                                                <GradeIcon sx={{ color: "#FF3E57", fontSize: "24px" }} />
                                                <Typography sx={{ color: "#FF3E57", fontSize: "14px", fontWeight: 600 }}>Efectividad 99/100</Typography>
                                            </Box>
                                        </Box>
                                        <Box m={2} sx={{ height: data.desktopDimensions.height/3.8, display: "flex", justifyContent: "center", overflow: 'auto' }}>
                                            {/* <img src={data?.adsImage?.image1 ? data?.adsImage?.image1 : "/assets/images/banner.svg"} height="100%" alt='' /> */}
                                            <Template3 data={data} image={data.image} division={3.8} />
                                        </Box>
                                        <Box sx={{ width: "100%" }}>
                                            <Grid container >
                                                <Grid item xs={6}>
                                                    <Box onClick={handleOpen} sx={{ width: "100%", background: "#FFB979", alignItems: "center", p: 1, display: "flex", justifyContent: "center", columnGap: 1, cursor: "pointer" }}>
                                                        <Typography sx={{ color: "#FF3E57", fontSize: "12px" }}>Edición rápida</Typography>
                                                        <CreateIcon sx={{ fontSize: "18px", color: "#FF3E57" }} />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Box onClick={() => handleSelect('3')} sx={{ width: "100%", background: "#FF3E57", alignItems: "center", p: 1, display: "flex", justifyContent: "center", columnGap: 1 }}>
                                                        <Typography sx={{ color: "white", fontSize: "12px" }}>Seleccionar</Typography>
                                                        <ArrowForwardIcon sx={{ fontSize: "18px", color: "white" }} />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            {/* <Grid item xs={12} md={3}>
                                <Box mr={2}>
                                    <Box sx={{ mx: 1, background: "white", borderRadius: "10px", overflow: "hidden" }}>
                                        <Box sx={{ width: "100%", background: "#FFB979", p: 1 }}>
                                            <Box sx={{ display: "flex", justifyContent: "center", columnGap: 1, alignItems: "center" }}>
                                                <GradeIcon sx={{ color: "#FF3E57", fontSize: "24px" }} />
                                                <Typography sx={{ color: "#FF3E57", fontSize: "14px", fontWeight: 600 }}>Efectividad 99/100</Typography>
                                            </Box>
                                        </Box>
                                        <Box m={2} sx={{ height: data.desktopDimensions.height/3.8, display: "flex", justifyContent: "center", overflow: 'auto' }}>
                                            <Template4 data={data} image={data.image} division={3.8} />
                                        </Box>
                                        <Box sx={{ width: "100%" }}>
                                            <Grid container >
                                                <Grid item xs={6}>
                                                    <Box onClick={handleOpen} sx={{ width: "100%", background: "#FFB979", alignItems: "center", p: 1, display: "flex", justifyContent: "center", columnGap: 1, cursor: "pointer" }}>
                                                        <Typography sx={{ color: "#FF3E57", fontSize: "12px" }}>Edición rápida</Typography>
                                                        <CreateIcon sx={{ fontSize: "18px", color: "#FF3E57" }} />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Box onClick={() => handleSelect('4')} sx={{ width: "100%", background: "#FF3E57", alignItems: "center", p: 1, display: "flex", justifyContent: "center", columnGap: 1 }}>
                                                        <Typography sx={{ color: "white", fontSize: "12px" }}>Seleccionar</Typography>
                                                        <ArrowForwardIcon sx={{ fontSize: "18px", color: "white" }} />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid> */}

                            <Grid item xs={12} md={3}>
                                <Box mr={2}>
                                    <Box sx={{ mx: 1, background: "white", borderRadius: "10px", overflow: "hidden" }}>
                                        <Box sx={{ width: "100%", background: "#FFB979", p: 1 }}>
                                            <Box sx={{ display: "flex", justifyContent: "center", columnGap: 1, alignItems: "center" }}>
                                                <GradeIcon sx={{ color: "#FF3E57", fontSize: "24px" }} />
                                                <Typography sx={{ color: "#FF3E57", fontSize: "14px", fontWeight: 600 }}>Efectividad 99/100</Typography>
                                            </Box>
                                        </Box>
                                        <Box m={2} sx={{ height: data.desktopDimensions.height/3.8, display: "flex", justifyContent: "center", overflow: 'auto' }}>
                                            {/* <img src={data?.adsImage?.image1 ? data?.adsImage?.image1 : "/assets/images/banner.svg"} height="100%" alt='' /> */}
                                            <Template5 data={data} image={data.image} division={3.8} />
                                        </Box>
                                        <Box sx={{ width: "100%" }}>
                                            <Grid container >
                                                <Grid item xs={6}>
                                                    <Box onClick={handleOpen} sx={{ width: "100%", background: "#FFB979", alignItems: "center", p: 1, display: "flex", justifyContent: "center", columnGap: 1, cursor: "pointer" }}>
                                                        <Typography sx={{ color: "#FF3E57", fontSize: "12px" }}>Edición rápida</Typography>
                                                        <CreateIcon sx={{ fontSize: "18px", color: "#FF3E57" }} />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Box onClick={() => handleSelect('5')} sx={{ width: "100%", background: "#FF3E57", alignItems: "center", p: 1, display: "flex", justifyContent: "center", columnGap: 1 }}>
                                                        <Typography sx={{ color: "white", fontSize: "12px" }}>Seleccionar</Typography>
                                                        <ArrowForwardIcon sx={{ fontSize: "18px", color: "white" }} />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            {/* )} */}
                        </Grid>
                    </Box >
                    <Box my={1}>
                        <CompareFooter active={active} setActive={setActive} btnClick={btnClick} />
                    </Box>
                </StepWrapper>
            </Box>
            <EditModal data={data} setData={setData} open={open} handleClose={handleClose} projectId={projectId} />
            <CustomFooter
                nextStep={nextStepClick}
                movePreviousStep={backToThirdStep}
                activeStep={activeStep}
                updateStep={updateStep}
                create={false}
            />
        </>
    )
}