import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userState } from "../recoil/atom/userState";
import Login from "../screens/sign-in";
// import Dashboard from "../screens/user/dashboard";
import DashboardLayout from "../layout/dashboard-layout";
import Start from "../screens/user/homepage";
import Register from "../screens/sign-up";
import ResetPasswordPage from "../screens/email";
import Profile from "../screens/user/profile";
import ProjectCreate from "../screens/user/project/create";
import ProjectEdit from "../screens/user/project/edit";

const PrivateRoutes = () => {
  const user = useRecoilValue(userState);
  if (!user) {
    return <Navigate to="/sign-in" />;
  }
  return <Outlet />;
};

const OtherRoutes = () =>{
  
  const user = useRecoilValue(userState);
  if(user){
    console.log(user,'other route')
    return <Navigate to="/" />;
  }
  return <Outlet />;
}

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <DashboardLayout>
        <Routes>
            <Route exact path="/sign-in" element={<Login />} />
            <Route exact path="/email" element={<ResetPasswordPage />} />
            <Route exact path="/sign-up" element={<Register />} />
          <Route element={<PrivateRoutes />}>
            <Route exact path="/user/profile" element={<Profile />} />
            <Route exact path="/" element={<Start />} />
            <Route exact path="/user/project/create" element={<ProjectCreate />} />
            <Route exact path="/user/project/edit" element={<ProjectEdit  />} />
          </Route>
        </Routes>
      </DashboardLayout>
    </BrowserRouter>
  );
};

export default AppRoutes;
