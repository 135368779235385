import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider
} from "firebase/auth";
import { auth } from "../config/firebaseConfig";


import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  deleteDoc,
  where,
  updateDoc,
  setDoc,
  getStorage,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../config/firebaseConfig";
import { storage } from "../config/firebaseConfig";
import { ref, uploadBytes,getDownloadURL,uploadString } from "firebase/storage";

export const UserEmailLogin = async (loginEmail, loginPassword) => {
  if (loginEmail == "" || loginPassword == "") {
    return { success: false, msg: "Please enter valid details" };
  }
  else {
    const resp = signInWithEmailAndPassword(auth, loginEmail, loginPassword)
      .then(async (userCredential) => {
        const user = userCredential.user;
        const userRef = doc(db, "users", user.uid)
        const snapShot = await getDoc(userRef)
        const respData = {
          name: snapShot.data().name,
          emai: snapShot.data().email,
          userId:snapShot.id,
          isAuthenticated:true
        }
        return { success: true, data: respData };
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        return { success: false, msg: errorMessage };
      });
    return resp

  }
}

export const getUserDetail = async() => {
  const user = localStorage.getItem("user");
  const uid = JSON.parse(user).userId;
  const userRef = doc(db, "users", uid)
  const snapShot = await getDoc(userRef)
  return snapShot.data();
}



export const UpdateDocument = async (data,docId)=> {
  try {
    console.log(data,docId)
    const userDocRef = doc(db, 'projects', docId)
    const result = await updateDoc(userDocRef, data);
    console.log(result,'ress') 
    return { docId:result,success:true};
  }
  catch (err) {
    console.log("err: ", err);
    return { success: false, error: err }
  }
}

export const GetDocument = async(docId) => {
  try {
    const result = await getDoc(doc(db,'projects',docId))
    console.log(result,'ressss') 
    return { data:result.data(),docId:result.id,success:true};
  }
  catch (err) {
    console.log("err: ", err);
    return { success: false, error: err }
  }
}

export const GetAllProject = async() => {
  try {
    const q = query(collection(db, 'projects'));
    const snapShot = await getDocs(q)
    const data = [];
    snapShot.forEach(doc => {
      console.log(doc.id, '=>', doc.data());
      data.push({...doc.data(),docId:doc.id})
    });
 
    return { data:data,success:true};
  }
  catch (err) {
    console.log("err: ", err);
    return { success: false, error: err }
  }
}


export const AddDocuments = async (data) => {
  try {
    const uid = localStorage.getItem("uid")
    console.log("LogoImage ", data.LogoImage.name);
    const storageRef = ref(storage, `/Logo/${data.LogoImage.name}`);
    const storageRef2 = ref(storage, `/Main/${data.MainImage.name}`);
    const saved = { ...data, uid: uid }
    delete saved.LogoImage
    delete saved.MainImage
    console.log("storage: ", storage);
    const file = data.LogoImage
    uploadBytes(storageRef, data.LogoImage).then((snapshot) => {
      console.log('Uploaded logo file!');
    });
    uploadBytes(storageRef2, data.MainImage).then((snapshot) => {
      console.log('Uploaded Main file!');
    });
    const url1 = await getDownloadURL(storageRef);
    const url2 = await getDownloadURL(storageRef2);
    const fullData = { ...saved, LogoImage: url1, MainImage: url2 }
    await addDoc(collection(db, 'advertisement'), fullData);
    // return { LogoImageUrl: url1, MainImageUrl: url2 };
  }
  catch (err) {
    console.log("err: ", err);
    return { success: false, error: err }
  }
}

export const createNewUserAccount = async (data) => {

  const { email, password } = data;
  const q = query(collection(db, 'users'), where('email', '==', email))
  try {
    const snapShot = await getDocs(q)
    if (snapShot && snapShot.empty) {
      // means not  user found
      //we will create the user now
      try {
        const userResult = await createUserWithEmailAndPassword(auth, email, password)
        const userDocRef = doc(db, 'users', userResult.user.uid)
        delete data.password
        await setDoc(userDocRef, data)
        const respData =  {
          name:data.name,
          email:data.email,
          userId:userResult.user.uid,
          isAuthenticated:true
        }
        return { success: true,data:respData}
      }
      catch (err) {
        // user already exists or any other error
        // return error to main caller
        return { success: false, error: err }
      }

    }
    else {
      return { success: false, error: 'User already exists or please try other email' }
      //user found
      // return error response that user is found
    }

  }
  catch (error) {
    return { success: false, error: error }
  }

};


export const resetPassword = async (email) => {
  return await sendPasswordResetEmail(auth, email);
};


export const socialLogin = async(loginType = 'google') => {
  let provider
  
  if(loginType == 'google'){
    provider = new GoogleAuthProvider();
  }
  else if(loginType == 'facebook'){
    provider = new FacebookAuthProvider();
    // provider.addScope('user_likes')
    provider.addScope('public_profile')
    // provider.addScope('pages_show_list')
    // provider.addScope('business_management')
    // provider.addScope('ads_management')
    // provider.addScope('instagram_basic')
  }
  console.log(provider,'provider')

  return signInWithPopup(auth, provider)
    .then(async (result) => {
      const credential = OAuthProvider.credentialFromResult(result)
      let access_token;
      let userId;
      if(loginType == 'facebook'){
        access_token = await getFbUserAccessToken(credential.accessToken)
        userId =  await getFbUserId(credential.accessToken);
      }
      else{
        access_token = credential.accessToken
      }

      console.log(credential,access_token,'tr')
      try{
        console.log(access_token,'access_token')
        const userSnapShot = await getDoc(doc(db,'users',result.user.uid))
        console.log(userSnapShot.exists(),'userSnapShot.exists()')

        // Check is user not Exist in database and if not exist in db then 
        // store in db

        // if(!userSnapShot.exists()){
        //   console.log(access_token,'access_tokenaccess_token')
        //   console.log('user not exist')
          const data = {
            email: result.user.email,
            name:  result.user.displayName,
            phoneNumber: result.user.phoneNumber,
            providerId: result.providerId,
            token:access_token,
            fbId:userId || ''
        }
          await setDoc(doc(db,'users',result.user.uid),data)
        // }

        const respData = {
          email: result.user.email,
          name:  result.user.displayName,
          userId:result.user.uid,
          isAuthenticated:true
        }

          return { success: true, data:respData }
      }
      catch(err){
        console.log('google login error',err)
        return { success: false, error: err }
      }
    }).catch((error) => {
      // Handle Errors here.
      console.log(error,'error')
      return { success: false, error: error }
    });
}

export const connectFacebook = async(loginType) => {
  let provider
  
 if(loginType == 'facebook'){
    provider = new FacebookAuthProvider();
    provider.addScope('user_likes')
    provider.addScope('public_profile')
    provider.addScope('pages_show_list')
    provider.addScope('business_management')
    provider.addScope('ads_management')
    provider.addScope('instagram_basic')
  }


  return signInWithPopup(auth, provider)
    .then(async (result) => {
      const credential = OAuthProvider.credentialFromResult(result)
      let access_token;
      let userId;
      if(loginType == 'facebook'){
        access_token = await getFbUserAccessToken(credential.accessToken)
        userId =  await getFbUserId(credential.accessToken);
      }
      else{
        access_token = credential.accessToken
      }
      try{
        console.log(access_token,'access_token')
        const user = localStorage.getItem("user");
        const uid = JSON.parse(user).userId;

          const data = {
            providerId: result.providerId,
            token:access_token,
        }
          await updateDoc(doc(db,'users',uid),data)
       

        const respData = {
          providerId: result.providerId,
            token:access_token,
        }

          return { success: true, data:respData }
      }
      catch(err){
        console.log('google login error',err)
        return { success: false, error: err }
      }
    }).catch((error) => {
      // Handle Errors here.
      console.log(error,'error')
      return { success: false, error: error }
    });
}

const getFbUserAccessToken = async (token) =>{
  const api = `https://graph.facebook.com/v16.0/oauth/access_token?`
  const apiUrl =  api+`grant_type=fb_exchange_token&client_id=3264276380489395&client_secret=90effae7ba97f2cba8af9c12920ea991&fb_exchange_token=${token}`
  return fetch(apiUrl,{
    method:'GET',
  })
  .then(res=>res.json())
  .then(data=>data.access_token)
  .catch(err=>console.log(err,'error'))
}

const getFbUserId = async(accessToken)=>{
  const api =  'https://graph.facebook.com/me?access_token=' + accessToken;
  return fetch(api,{
    method:'GET',
  })
  .then(res => res.json())
  // Retrun facebook User id which need used for further API Calls
  // example data reutned {name: 'Neha Sharma', id: '3381447915447753'}
  .then(data=>data.id)
  .catch(err=>console.log('Faceebook Me error',err))

}


export const getFbUserPemissions = async(fbId,accessToken)=>{
  const api = `https://graph.facebook.com/v16.0/${fbId}/permissions?access_token=${accessToken}`
  return fetch(api,{
    method:'GET',
  })
  .then(res=>res.json())
  .then(data=>data.data)
  .catch(err=>console.log('Error in getting users permission',err))
}

export const revokeFbPermissions =  async (fbId,permisson,accessToken)=>{
  const api = `https://graph.facebook.com/v16.0/${fbId}/permissions/${permisson}?access_token=${accessToken}`
  return fetch(api,{
    method:'DELETE'
  })
  .then(res=>res.json())
  .then(data=>data)
  .catch(err=>console.log('Error in deleting users permission',err))
}


export const deleteFbPermissions  = async(fbId,accessToken) =>{
  const api = `https://graph.facebook.com/v16.0/${fbId}/permissions?access_token=${accessToken}`
  return fetch(api,{
    method:'DELETE'
  })
  .then(res=>res.json())
  .then(data=>data)
  .catch(err=>console.log('Error in deleting users permission',err))
}
