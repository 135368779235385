const DefaultPalette = () => {
  // ** Vars
  const mode = "light";
  // const mainColor = mode === "light" ? "31, 31, 31" : "255,255,255";

  return {
    customColors: {
      main: "#017BFF",
    },
    mode: 'light',
    common: {
      black: "#000",
      white: "#FFF",
      dark: "#2A2D2E",
      grey: "#808080",
      greyF2: "#F2F2F2",
      greyB3: "#B3B3B3",
      greenB2: "#B2FBCD",
      grey4d: "#4D4D4D",
      lightGreen: "#9AF3BB",
      grey97: "#979797",
    },
    primary: {
      light: "#9E69FD",
      main: "#FE545C",
      dark: "#FF3E57",
      contrastText: "#FFF",
    },
    lightGreen: {
      main: "#9AF3BB",
    },
    secondary: {
      main: "#E5F8EC",
      contrastText: "#FFF",
    },
    success: {
      light: "#6AD01F",
      main: "#56CA00",
      dark: "#4CB200",
      contrastText: "#FFF",
    },
    error: {
      light: "#FF6166",
      main: "#FF4C51",
      dark: "#E04347",
      contrastText: "#FFF",
    },
    warning: {
      light: "#FFCA64",
      main: "#FFB400",
      dark: "#E09E00",
      contrastText: "#FFF",
    },
    info: {
      light: "#32BAFF",
      main: "#16B1FF",
      dark: "#139CE0",
      contrastText: "#FFF",
    },
    grey: {
      50: "#FAFAFA",
      100: "#F5F5F5",
      200: "#EEEEEE",
      300: "#E0E0E0",
      400: "#BDBDBD",
      500: "#9E9E9E",
      600: "#757575",
      700: "#616161",
      800: "#424242",
      900: "#212121",
      A100: "#D5D5D5",
      A200: "#AAAAAA",
      A400: "#616161",
      A700: "#303030",
    },
    text: {
      // primary: mode === "light" ? `rgba(${mainColor}, 1)` : "#fff",
      // secondary: `rgba(${mainColor}, 0.68)`,
      // disabled: `rgba(${mainColor}, 0.38)`,
      shadow: mode === "light" ? "#d2d2d2" : "#000",
      lightWhite: mode === "light" ? "#1f1f1f" : "#fff",
    },
    // divider: `rgba(${mainColor}, 0.12)`,
    background: {
      paper: mode === "light" ? "#FFF" : "#282A35",
      default: mode === "light" ? "#F4F5FA" : "#28243D",
    },
    // action: {
    //   active: `rgba(${mainColor}, 0.54)`,
    //   hover: `rgba(${mainColor}, 0.04)`,
    //   selected: `rgba(${mainColor}, 0.08)`,
    //   disabled: `rgba(${mainColor}, 0.3)`,
    //   disabledBackground: `rgba(${mainColor}, 0.18)`,
    //   focus: `rgba(${mainColor}, 0.12)`,
    // },
  };
};

export default DefaultPalette;
