import React from 'react'
import { Box } from '@mui/system'
import { Button, Grid, Typography } from '@mui/material'
import StepWrapper from '../../stepwrapper'
import CustomHeader from '../customheader'
import styled from '@emotion/styled'
import Buttons from '../../../atoms/buttons/Button'
import { ArrowForward } from '@mui/icons-material'
import CustomFooter from '../customfooter'

const BoxWrapper = styled(Button)`
display: flex;
flex-direction: column; 
align-items: center;
width: 100%;
height: 100%;
display: flex;
justify-content: space-between;
color:black;
gap:8px
`

const Reel = [1, 2, 3]

export default function Step5({ data, setData, updateStep, activeStep, SaveData }) {
    const height = window.innerHeight;

    const backToFourthStep = () => {
        updateStep(activeStep - 1)
    }

    return (
        <>
            <Box sx={{
                mt: { md: `calc(${height}px - ${height - 60}px)`, xs: 2 },
                mb: `calc(${height}px - ${height - 55}px)`,
                overflowY: { md: "hidden", xs: "auto" },
                height: "100%",
            }}>
                <CustomHeader
                    title="Publicar"
                    description="Comparta su diseño en sus redes"
                    SaveData={SaveData}
                />
                <StepWrapper style={{ px: { md: 6, xs: 0 }, overflowY: 'auto', my: 2, }}>
                    <Box>
                        <Grid fullWidth container>
                            <Grid item xs={12} md={4}>
                                <img src={data?.adsImage?.image1 ? data.adsImage.image1 : '/assets/images/step2/Clasico.png'}  style={{height:height - 260,width:"auto"}} alt='' />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                    <Grid sx={{ my: 2, ml: { md: 4, xs: 0 },mt:'calc(20% - 110px)', textAlign: { xs: "center", md: "left" } }} container>
                                        <Grid item xs={12} md={3}>
                                            <Typography color={'#4d4d4d'} sx={{ fontWeight: "bold", fontSize: "24px", lineHeight: 1,paddingRight:5 }}>Publicar en Instagram</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                           <Buttons.RedButton sx={{
                                            padding: '10px',
                                            minWidth: '240px',
                                           }}>
                                            Publicar
                                            <ArrowForward color='white' />
                                           </Buttons.RedButton>
                                        </Grid>
                                    </Grid>

                                    <Grid sx={{ my: 2, ml: { md: 4, xs: 0 },mt:'calc(20% - 110px)', textAlign: { xs: "center", md: "left" } }} container>
                                        <Grid item xs={12} md={3}>
                                            <Typography color={'#4d4d4d'}  sx={{ fontWeight: "bold", fontSize: "24px", lineHeight: 1 ,paddingRight:5}}>Publicar en Facebook</Typography>
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                           <Buttons.RedButton sx={{
                                            padding: '10px',
                                            minWidth: '240px',
                                           }}>
                                            Publicar
                                            <ArrowForward color='white' />
                                           </Buttons.RedButton>
                                        </Grid>
                                    </Grid>
                               
                            </Grid>
                        </Grid>
                    </Box >
                </StepWrapper>
            </Box>
            <CustomFooter
                nextStep={console.log('')}
                movePreviousStep={backToFourthStep}
                activeStep={activeStep}
                updateStep={updateStep}
                nextDisabled={true}
                create={false}
            />
        </>
    )
}