import Box from "@mui/system/Box"
export default function StepWrapper({ children, style, style2 }) {
    const height = window.innerHeight;
    return (
        <Box sx={{
            height: `100%`,
            // overflowY: "auto",
            // overflowX: "hidden",
            ...style
        }}>
            <Box sx={{ height: "100%", py: 1, ...style2 }}>
                {children}
            </Box>
        </Box>
    )
}
