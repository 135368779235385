import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    deleteDoc,
    where,
    updateDoc,
    setDoc,
    getStorage,
  } from "firebase/firestore";

import { db } from "../config/firebaseConfig";

export const getUserById = async (userId) =>{
    const docRef = doc(db,'users',userId)
    try{
        const userSnapshot = await getDoc(docRef)
        return {success:true, data:userSnapshot.data()}
    }
    catch(err){
        console.log('No user found with docId')
        return{success:false,err:err}

    }

}