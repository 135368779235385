import { Grid, Typography } from '@mui/material'
import React from 'react'
import Buttons from '../../atoms/buttons/Button'
import RoundedContainer from '../roundedcontainer'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import styled from '@emotion/styled';

const ButtonCount = styled(Typography)`
    background: white;
    width: 20px;
    height: 20px;
    color: #ffb979;
    border-radius: 50%;
    margin-right: 5px;
    font-size: 14px;
    line-height: 1.5;
`

const ButtonName = ["Creación", "Formato", "Texto, CTA e imagen", "Diseños", "Publicar"]

export default function CustomFooter({ movePreviousStep, nextStep, activeStep,updateStep, setactiveStep, checkStep,btnClick,previousDisabled,nextDisabled,create }) {
    return (
        <>
            <RoundedContainer
                style={{ display: "flex", alignItems: "center" }}
                style2={{ height: "50px", position: "absolute", width: "100%", bottom: 0 }}
            >
                <Grid sx={{ display: "flex" }} container fullWidth>
                    <Grid item xs={6} md={1}>
                        {
                            !previousDisabled && (
                                <Buttons.RedButton sx={{ float: 'left', my: 2 }} onClick={movePreviousStep}>
                                <ArrowBackIcon />
                            </Buttons.RedButton>
                            )
                        }

                    </Grid>
                    <Grid item sx={{ justifyContent: "space-around", display: { xs: "none", md: "flex" } }} xs={10}>
                        {ButtonName.map((buttonName, index) =>
                            <div key={index}>{activeStep === index + 1 ? (
                                <Buttons.DarkYellowButton sx={{ my: 2 }}>
                                    <ButtonCount>{index + 1}</ButtonCount>
                                    {buttonName}
                                </Buttons.DarkYellowButton>
                            ) :
                                (<Buttons.LightYellowButton sx={{ my: 2 }} onClick={() => {if(create === false) {updateStep(index+1)}}}>
                                    <ButtonCount>{index + 1}</ButtonCount>
                                    {buttonName}
                                </Buttons.LightYellowButton>)
                            }
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={6} md={1}>
                        {
                            ! nextDisabled &&(
                                <Buttons.RedButton sx={{ float: 'right', my: 2 }} onClick={nextStep}>
                                <ArrowForwardIcon />
                            </Buttons.RedButton>
                            )
                        }
                            
                    </Grid>
                </Grid>
            </RoundedContainer>
        </>
    )
}
