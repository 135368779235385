import Box from "@mui/system/Box"
export default function RoundedContainer({ children, style, style2 }) {
    return (
        <Box sx={{
            borderRadius: "12px",
            border: "1px solid #FE545C",
            //overflow: "hidden",
            ...style2
        }}>
            <Box sx={{
                width: "100%", height: "100%",
                boxSizing: "border-box",
                background: " rgba(255, 255, 255, 0.5)",
                py: "8px", px: "16px", ...style
            }}>
                {children}
            </Box>
        </Box>
    )
}
