import { Button } from "@mui/material";
import styled from "@emotion/styled";

const SimpleButton = styled(Button)`
  border-width: 0;
  padding: 5px 10px;
  border-color: #000000;
  font-weight: 200;
  text-transform: none;
  border-radius: 16px;
  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #FF3E57;
  }
`;

const RedButton = styled(SimpleButton)`
background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #FF3E57;
color: #FFF;
`;
const GreenButton = styled(SimpleButton)`
background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #257e06;
color: #FFF;
&:hover {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), #257e06;
}
`;
const MaroonButton = styled(SimpleButton)`
background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #B40017;
color: #FFF;
`;
const WhiteButton = styled(SimpleButton)`
background: rgba(255, 255, 255, 0.5);
color: #000;
`;
const DisableButton = styled(SimpleButton)`
background: rgba(158, 156, 156, 0.5);
color: #FFF;
`;
const DarkYellowButton = styled(SimpleButton)`
background: #FFB979;
color: #FFF;
`;
const LightYellowButton = styled(SimpleButton)`
background: #FFCD9F;
color: #FFF;
`;
const OrangeButton = styled(SimpleButton)`
background: #FE7D68;
color: #FFF;
`;
const LightBlueButton = styled(SimpleButton)`
background: #54ACFE;
color: #FFF;
`;
const DarkBlueButton = styled(SimpleButton)`
background: #153E63;
color: #FFF;
`;
const Buttons = {
  RedButton,
  MaroonButton,
  WhiteButton,
  DisableButton,
  DarkYellowButton,
  LightYellowButton,
  OrangeButton,
  LightBlueButton,
  DarkBlueButton,
  GreenButton
};
export default Buttons;
