// ** MUI Theme Provider
import { deepmerge } from "@mui/utils";

// ** Theme Override Imports
import palette from "./palette";
// import { spacing } from "./spacing";
const themeOptions = (settings) => {
  // ** Vars
  const theme = settings;

  const themeConfig = {
    palette: palette(theme),
    typography: {
      button: {
        textTransform: "none",
      },
      fontFamily: ["Montserrat"].join(","),
    },
    // customSpacing: spacing,
    // components: {
    //   MuiTypography: {
    //     defaultProps: {
    //       variantMapping: {
    //         subtitle: "div",
    //         label: "div",
    //       },
    //     },
    //   },
    // },
  };

  return deepmerge(themeConfig, {
    palette: {
      primary: {
        ...themeConfig.palette[theme],
      },
    },
  });
};

export default themeOptions;
